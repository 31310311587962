@import '../../../assets/styles/colors.scss';


.section-container{
  max-width: 53rem;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 1.563rem;

  .large-button{
    min-width: 12.938rem;
    margin: 0 auto;
  }

  .final-text{
    max-width: 30.938rem;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 0.75rem;
    line-height: 1.188rem;
    color: $darkGray500;
    text-align: center;
    letter-spacing: 0;
    margin: 0;
    display: block;
    cursor: inherit;
  }
}

@media only screen and (max-width: 61.938rem){
  .section-container{
    max-width: 20.563rem;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2.5rem;
  
    .large-button{
      min-width: 20.563rem;
      margin: 0 auto;
    }
  
    .final-text{
      max-width: 30.938rem;
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 0.75rem;
      line-height: 1.188rem;
      color: $darkGray500;
      text-align: center;
      letter-spacing: 0;
      margin: 0;
      display: block;
      cursor: inherit;
      margin-bottom: 3rem;
    }
  }
}