@import "../../../assets/styles/colors.scss";

.data-form-express-request {
  .titleCheck {
    padding-top: 3rem;
    padding-bottom: 2.5rem;
  }

  .button-container {
    margin-top: 3.5rem;
    margin-bottom: 3rem;
    max-width: 28.5rem;
  }

  .form-input {
    padding: 0;

    .text-label {
      color: var(--darkGrey500);
      font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1rem;
      line-height: 1.625rem;
      text-align: left;
    }

    .tooltip-container {
      margin-top: 0.15rem;
    }

    .subtitle-section {
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: $grayscale500;
      margin-bottom: 1rem;
    }
  }

  .wrapper {
    max-width: 100%;
    display: block;

    .large {
      width: calc(100% - 3rem);
    }
  }
}

@media (max-width: 61.938rem) {
  .data-form-express-request {
    .titleCheck {
      padding-top: 2.5rem;
      max-width: 20.5rem;
      margin: 0 auto;
      padding-bottom: 0.5rem;
    }

    .subtitle {
      max-width: 20.5rem;
      font-size: 0.875rem;
    }

    .steps-container {
      margin: 0 auto;
      margin-top: 2.5rem;
    }

    .button-container {
      margin-top: 2rem;
      padding: 0;
      margin-bottom: 1rem;
    }
    .wrapper {
      .large {
        width: calc(100% - 2rem);
      }
      .phone-wrapper {
        width: 100%;
      }
    }

    .invalid-request {
      margin-bottom: 0;
    }
  }
}

.invalid-request {
  position: relative !important;
  max-width: 39rem !important;
  width: unset !important;
  margin: 0 auto;
  margin-bottom: -0.75rem;
  z-index: 100;
  svg {
    margin-top: 0.1rem;
  }
}
