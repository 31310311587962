.ct__confirmation {
  margin-top: 8rem;
  max-width: 624px;
  width: 100%;
  text-align: center;

  .ct__confirmation-container {
    max-width: 391px;
    width: calc(100vw - 32px);

    .ct__confirmation-typography {
      &.align-left {
        text-align: left;
      }

      .ct__confirmation-typography-check-text {
        img,
        pichincha-typography {
          display: inline-block;
          vertical-align: middle;
        }

        img {
          margin-right: 11px;
          width: 17px;
        }

        pichincha-typography {
          width: calc(100% - 28px);
        }
      }
    }
  }

  .ct__confirmation-button-container {
    text-align: center;

    .ct__confirmation-button {
      display: inline-block;
    }
  }
}

@media (max-width: 991px) {
  .ct__confirmation-button-container {
    display: flex;
    flex-direction: column;
    max-width: 328px;
    margin: 0 auto;

    .ct__confirmation-button {
      &.pl-12 {
        padding-left: 0;
        padding-bottom: 12px;
        order: 1;
      }

      &.pr-12 {
        padding-right: 0;
        padding-top: 12px;
        order: 2;
      }
    }
  }
  .message-bar-container {
    &.success-facephi {
      padding: 0;
      max-width: 328px;
    }
  }
}

@media (min-width: 1200px) {
  .message-bar-container {
    &.success-facephi {
      padding: 0;
      margin-top: 3rem;
    }
  }
}
