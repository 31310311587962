@font-face {
  font-family: 'preloslab-book';
  src: url('./pichincha-fonts/preloslab-book/preloslab-book.eot');
  src: local('preloslab-book'),
    url('./pichincha-fonts/preloslab-book/preloslab-book.eot?#iefix')
      format('embedded-opentype'),
    url('./pichincha-fonts/preloslab-book/preloslab-book.otf')
      format('opentype'),
    url('./pichincha-fonts/preloslab-book/preloslab-book.woff') format('woff'),
    url('./pichincha-fonts/preloslab-book/preloslab-book.ttf')
      format('truetype'),
    url('./pichincha-fonts/preloslab-book/preloslab-book.svg#preloslab-book')
      format('svg');
  font-style: normal;
}

@font-face {
  font-family: 'preloslab-semibold';
  src: url('./pichincha-fonts/preloslab-semibold/preloslab-semibold.eot');
  src: local('preloslab-semibold'),
    url('./pichincha-fonts/preloslab-semibold/preloslab-semibold.eot?#iefix')
      format('embedded-opentype'),
    url('./pichincha-fonts/preloslab-semibold/preloslab-semibold.otf')
      format('opentype'),
    url('./pichincha-fonts/preloslab-semibold/preloslab-semibold.woff')
      format('woff'),
    url('./pichincha-fonts/preloslab-semibold/preloslab-semibold.ttf')
      format('truetype'),
    url('./pichincha-fonts/preloslab-semibold/preloslab-semibold.svg#preloslab-semibold')
      format('svg');
  font-style: normal;
}

@font-face {
  font-family: 'prelo-medium';
  src: url('./pichincha-fonts/prelo-medium/prelo-medium.eot');
  src: local('prelo-medium'),
    url('./pichincha-fonts/prelo-medium/prelo-medium.eot?#iefix')
      format('embedded-opentype'),
    url('./pichincha-fonts/prelo-medium/prelo-medium.otf') format('opentype'),
    url('./pichincha-fonts/prelo-medium/prelo-medium.woff') format('woff'),
    url('./pichincha-fonts/prelo-medium/prelo-medium.ttf') format('truetype'),
    url('./pichincha-fonts/prelo-medium/prelo-medium.svg#prelo-medium')
      format('svg');

  font-style: normal;
}

@font-face {
  font-family: 'prelo-bold';
  src: url('./pichincha-fonts/prelo-bold/prelo-bold.eot');
  src: local('prelo-bold'),
    url('./pichincha-fonts/prelo-bold/prelo-bold.eot?#iefix')
      format('embedded-opentype'),
    url('./pichincha-fonts/prelo-bold/prelo-bold.otf') format('opentype'),
    url('./pichincha-fonts/prelo-bold/prelo-bold.woff') format('woff'),
    url('./pichincha-fonts/prelo-bold/prelo-bold.ttf') format('truetype'),
    url('./pichincha-fonts/prelo-bold/prelo-bold.svg#prelo-bold') format('svg');

  font-style: normal;
}
@font-face {
  font-family: 'prelo-semibold';
  src: url('./pichincha-fonts/prelo-semibold/prelo-semibold.eot');
  src: local('prelo-bold'),
    url('./pichincha-fonts/prelo-semibold/prelo-semibold.eot?#iefix')
      format('embedded-opentype'),
    url('./pichincha-fonts/prelo-semibold/prelo-semibold.otf')
      format('opentype'),
    url('./pichincha-fonts/prelo-semibold/prelo-semibold.woff') format('woff'),
    url('./pichincha-fonts/prelo-semibold/prelo-semibold.ttf')
      format('truetype'),
    url('./pichincha-fonts/prelo-semibold/prelo-semibold.svg#prelo-bold')
      format('svg');

  font-style: normal;
}

@font-face {
  font-family: 'prelo-light';
  src: url('./pichincha-fonts/prelo-light/Prelo-Light.ttf');
  src: local('prelo-light'),
    url('./pichincha-fonts/prelo-light/Prelo-Light.woff') format('woff'),
    url('./pichincha-fonts/prelo-light/Prelo-Light.ttf')
      format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'prelo-extralight';
  src: url('./pichincha-fonts/prelo-extralight/Prelo-ExtraLight.ttf');
  src: local('prelo-light'),
    url('./pichincha-fonts/prelo-extralight/Prelo-ExtraLight.woff') format('woff'),
    url('./pichincha-fonts/prelo-extralight/Prelo-ExtraLight.ttf')
      format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'prelo-book';
  src: url('./pichincha-fonts/prelo-book/Prelo-Book.ttf');
  src: local('prelo-light'),
    url('./pichincha-fonts/prelo-book/Prelo-Book.woff') format('woff'),
    url('./pichincha-fonts/prelo-book/Prelo-Book.ttf')
      format('truetype');
  font-style: normal;
}


/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./material-fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2') format('woff2');
}

/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('./material-fonts/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2') format('woff2');
}

