.navbar-container {
    background-color: var(--white);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 97;
}

.session-bar {
    display: flex;
    align-items: center;
}

@media (min-width: 1200px) {
    .session-bar {
        margin-right: -2.25rem;
    }
}

@media (max-width: 991px) {
    .session-bar {
        margin-right: 0;
    }
  }

.navbar{
    &--container{
      box-shadow: 0 0.25rem 0.25rem rgba(15, 38, 92, 0.1);
      background-color: var(--white);    
      width: 100%;
      height: 5rem;
      z-index: 500;
      position: relative;
      
      .contentNavbar{
        display: block;
        padding: 1.25rem 0;
        max-width: calc(100vw - 14%);
        margin: 0 auto;

        &.express-application-page {
            max-width: 75.25rem;
        }
        
        
        .table{

            display: flex;
            justify-content: space-between;
            align-items: center;
            .left{
                vertical-align: middle;
                .button-ico{
                    width: 9.188rem;
                    height: 2rem;
                    position: absolute;
                }
            }
            .responsive{
                vertical-align: middle;
                visibility: hidden;
                position: absolute;
                .button-ico-responsive{
                    width: 6.875rem;
                    height: 1.5rem;
                    position: absolute;
                }

            }
            .right{
                
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
                justify-items: center;
                align-items: center;
                .buttonline{
                    background-color: white;
                    border:none;
                    visibility: hidden;                    
                }
                .buttoncross{
                    background-color: white;
                    border:none;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 1.5rem;
                    width: 1.5rem;
                    visibility: hidden;
                }
                
                .item{
                    padding: 0 0 0 1rem;
            
                        &--link {
                          color: var(--darkGrey500);
                          font-size: 0.875rem;
                          font-weight: 700;
                          &.active {
                              color: var(--blue500);
                              border-bottom: 0.188rem solid var(--blue500);
                          }
                        }
                }
            }
        }
    }
    
    .contentNavbarLogin{
        display: block;
        padding: 1.25rem 5.25rem;
        max-width: 79.5rem;
        margin: 0 auto;
        
        
        .table{

            display: flex;
            justify-content: space-between;
            align-items: center;
            .left{
                vertical-align: middle;
                .button-ico{
                    width: 9.188rem;
                    height: 2rem;
                    position: absolute;
                }
            }
            .responsive{
                vertical-align: middle;
                visibility: hidden;
                position: absolute;
                .button-ico-responsive{
                    width: 6.875rem;
                    height: 1.5rem;
                    position: absolute;
                }

            }
            .right{
                
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
                justify-items: center;
                align-items: center;
                .buttonline{
                    background-color: white;
                    border:none;
                    visibility: hidden;                    
                }
                .buttoncross{
                    background-color: white;
                    border:none;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 1.5rem;
                    width: 1.5rem;
                    visibility: hidden;
                }
                
                .item{
                    padding: 0 0 0 1rem;
            
                        &--link {
                          color: var(--darkGrey500);
                          font-size: 0.875rem;
                          font-weight: 700;
                          &.active {
                              color: var(--blue500);
                              border-bottom: 0.188rem solid var(--blue500);
                          }
                        }
                }
            }
        }
    }

    }
    
  }


.backdrop-style{
    position:fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(74, 74, 80, 0.8);
    z-index: 100;
    visibility: hidden;
}

.side-drawer{
    height: 100%;
    background: var(--white);
    position: fixed;
    top:0;
    right: 0;
    width: 15.25rem;
    z-index: 200;
    padding: 1rem 1rem;
    margin-top: 3.5rem;
    display: flex;
    flex-direction: column;
    visibility: hidden;

    .divider{
        position: static;
        padding: 0 0;
        width: 15.25rem;
        height: 0.063rem;
        margin: 0.5rem 0;
        background-color: var(--darkGrayishBlue300);
    }
    .item{
        padding:  0.5rem 0;


            &--link {
              color: var(--darkGrey500);
              font-size: 0.875rem;
              line-height: 1.026rem;
              font-weight: 700;
              &.active {	
                  color: var(--blue500);	
                  border-bottom: 0.188rem solid var(--blue500);	
              }
            }
    }
}

@media only screen and (max-width: 61.938rem) {
    .navbar{
        &--container{
          background-color: var(--white);
          padding: 1.25rem auto;
          box-shadow: 0 0.25rem 0.25rem rgba(15, 38, 92, 0.1);    
          width: 100%;
          height: 3.5rem;
          position: relative;

            
          .contentNavbar{
            display: block;
            padding: 1rem 1rem;
            .table{
                display: flex;
                justify-content: space-between;
                height: 1.5rem;
                align-items: center;

                
                .left{
                    position: fixed;
                    visibility: hidden;
                }
                .responsive{
                    vertical-align: middle;
                    visibility: visible;
                    position: relative;
                }
                .right{
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-end;
                    justify-items: center;
                    align-items: center;
                    flex-direction: column;
                    .buttonline{
                        background-color: white;
                        border:none;
                        cursor: pointer;
                        visibility: visible;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .line{
                            width: 1.125rem;
                            height: 0.125rem;
                            background: var(--blue500);
                            margin: 0.063rem;
                            cursor: pointer;
                        }
                        .cross{
                            position: absolute;
                            visibility: hidden;
                            
                        }
                    }

                    .buttoncross{
                        background-color: white;
                        border:none;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        height: 1.5rem;
                        width: 1.5rem;
                        visibility: visible;
                    }
                    
                    .item{
                        position: fixed;
                        visibility: hidden;
    
                    }
                }
            }
        }
        .contentNavbarLogin{
            display: block;
            padding: 1rem 1.5rem;
            .table{
                display: flex;
                justify-content: space-between;
                height: 1.5rem;
                align-items: center;

                
                .left{
                    position: fixed;
                    visibility: hidden;
                }
                .responsive{
                    vertical-align: middle;
                    visibility: visible;
                    position: relative;
                }
                .right{
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-end;
                    justify-items: center;
                    align-items: center;
                    flex-direction: column;
                    .buttonline{
                        background-color: white;
                        border:none;
                        cursor: pointer;
                        visibility: visible;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .line{
                            width: 1.125rem;
                            height: 0.125rem;
                            background: var(--blue500);
                            margin: 0.063rem;
                            cursor: pointer;
                        }
                        .cross{
                            position: absolute;
                            visibility: hidden;
                            
                        }
                    }

                    .buttoncross{
                        background-color: white;
                        border:none;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        height: 1.5rem;
                        width: 1.5rem;
                        visibility: visible;
                    }
                    
                    .item{
                        position: fixed;
                        visibility: hidden;
    
                    }
                }
            }
        }
        }
    
        
    }
    .side-drawer{
        visibility: visible;
    }
    .backdrop-style{
        visibility: visible;
    }
}

