.rtb-wrapper {
  margin-top: 24px;
  margin-bottom: 24px;

  .rtb-label {
    color: var(--darkGrey500);
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: left;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 10px;
  }

  .rtb-item {
    .rtb-item__cell-phone,
    .rtb-item__email,
    .rtb-item__general {
      display: inline-block;
      vertical-align: middle;

      div {
        display: inline-block;
      }
    }

    .rtb-item__cell-phone {
      width: 80px;
    }

    .rtb-item__email {
      margin-left: 2rem;
    }

    .rtb-item__general {
      margin-right: 40px;
    }
  }

  .rtb-input--hidden {
      display: none;
  }
}
