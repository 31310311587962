@import "../assets/styles/colors.scss";

.popover {
  position: relative;

  .link {
    padding: 0;
    padding-bottom: 0.25rem;
    .text {
      font-size: 0.875rem;
    }
    .append-text {
      margin-top: 0.2rem;
      cursor: pointer;
      color: var(--blue300);
      font-size: 0.75rem;
    }
  }

  .popover-content-visible {
    position: absolute;
    width: 16.875rem;
    padding: 1rem 1rem 1.5rem 1rem;
    background: white;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    border: 0.063rem solid $darkGray100;
    box-sizing: border-box;
    box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(206, 198, 198, 0.16);
    border-radius: 0.25rem;

    visibility: visible;
    z-index: 950;

    .poporver-title-section {
      display: flex;
      justify-content: space-between;
      .title-popover {
        font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
        font-size: 0.875rem;
        line-height: 1.438rem;
        color: $blue500;
        text-align: left;
        padding-bottom: 0.5rem;
      }
      pichincha-icon {
        cursor: pointer;
      }
    }

    .contain-popover {
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-weight: normal !important;
      font-size: 0.75rem;
      line-height: 1.188rem;
      color: $darkGray500;
      text-align: left;

      letter-spacing: 0;

      margin: 0;
      display: block;
      cursor: inherit;
      .contain-bold {
        font-weight: bold;
      }
    }
  }

  .popover-content-hidden {
    position: absolute;
    visibility: hidden;
    display: none;
  }
}
