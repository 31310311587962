@import "../../../assets/styles/colors.scss";

.message-bar-container {
  padding-top: 2.5rem;
}

.credit-data-form-express-request {
  .form-input {
    width: 42.5rem;
    padding: 0;
  }
  .titleCheck {
    font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
    font-size: 2rem;
    line-height: 2.375rem;
    color: #0f265c;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
  .title-products-button {
    font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $darkGray500;
  }

  .button-credit-product {
    background: white;
    border: 0.063rem solid $darkGray300;
    box-sizing: border-box;
    border-radius: 0.25rem;
    width: 100%;
    max-width: 42rem;
    height: 3.5rem;
    cursor: pointer;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $darkGray500;
  }
  .button-credit-product-selected {
    background: $circleColor;
    border: 0.125rem solid $info;
    box-sizing: border-box;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 42rem;
    height: 3.5rem;
    cursor: pointer;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $darkGray500;
  }
  .button-container {
    margin-top: 3.5rem;
    .large-button {
      width: 100%;
    }
  }
  .last-text {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    text-align: justify;
    color: $darkGray500;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 0.75rem;
    line-height: 1.188rem;
  }
}

.disabled-vip-vis-message {
  margin: 24px;
  hr {
    margin-top: 0.969rem;
    margin-bottom: 1.469rem;
  }
}

pichincha-link {
  text-align: center;
}

@media (max-width: 61.938rem) {
  .message-bar-container {
    margin-top: 0rem !important;
  }

  .credit-data-form-express-request {
    .form-input {
      min-width: 20.5rem;
      margin: 0 1rem;
    }

    .titleCheck {
      padding-top: 2rem;
      max-width: 20.5rem;
      margin: 0 auto;
      padding-bottom: 0.5rem;
    }

    .subtitle {
      max-width: 20.5rem;
      font-size: 0.875rem;
    }

    .steps-container {
      margin: 0 auto;
      margin-top: 2.5rem;
    }

    .button-container {
      margin-top: 2rem;
      padding: 0;
      margin-bottom: 2.5rem;
    }
    .wrapper {
      .large {
        width: calc(100% - 2rem);
      }
      .phone-wrapper {
        width: 100%;
      }
    }
    .last-text {
      text-align: center;
      margin: 2.5rem 0;
    }
  }
}
