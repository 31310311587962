.section {
  max-width: 53rem;
  margin: 0 auto;
}

@media (max-width: 992px) {
  .section {  
    max-width: 20.5rem;
    padding-left: 1rem;
    &.express {  
      padding: 0;
      max-width: initial;
    }
  }
  
}

.disabled {
  opacity: 0.5;
}

pichincha-tooltip {
  vertical-align: middle;
}