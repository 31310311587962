@import '../../assets/styles/colors.scss';

.rectangle-amortization{
  display: grid;
  background: white;
  border: 0.063rem solid $darkGray300;
  box-sizing: border-box;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 25.5rem;
  height: 6.75rem;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  
  .display{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    
    .textBox-limit{
      max-width: 18.5rem;
      .box-title{
        color: var(--blue500);
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        font-size: 1.125rem;
        line-height: 1.375rem;
      }
      .box-description{
        color: $darkGray500;
        font-size: 0.875rem;
        margin: 0;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        line-height: 1.438rem;
      }
    }
    .check-align{
      margin-right: 1.5rem;
    }
    
  }
  
}

.rectangle-amortization-selected{
  display: grid;
  border: 0.125rem solid #2F7ABF;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #F4F6F9;
  width: 25.5rem;
  height: 6.75rem;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  

  .display{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    .textBox-limit{
      max-width: 18.5rem;
      .box-title{
        color: $blue500;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        font-size: 1.125rem;
        line-height: 1.375rem;
      }
      .box-description{
        color: $darkGray500;
        font-size: 0.875rem;
        margin: 0;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        line-height: 1.438rem;
      }
    }
    .check-align{
      margin-right: 1.5rem;
    }
  }
}

@media only screen and (max-width: 61.938rem){
  .rectangle-amortization{
    width: 20.5rem;
    height: auto;

    .display{
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      .textBox-limit{
        max-width: 18.5rem;
        .box-title{
        color: $blue500;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        font-size: 1rem;
        line-height: 1.625rem;
      }
      .box-description{
        color: $darkGray500;
        margin: 0;
        font-size: 0.75rem;
        font-family:  prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
        line-height: 1.188rem;
      }
      }
      .check-align{
        margin-right: 1.5rem;
      }      
    }
  }

  .rectangle-amortization-selected{
    width: 20.5rem;
    height: auto;

    .display{
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      .textBox-limit{
        max-width: 18.5rem;
        .box-title{
        color: $blue500;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        font-size: 1rem;
        line-height: 1.625rem;
      }
      .box-description{
        color: $darkGray500;
        margin: 0;
        font-size: 0.75rem;
        font-family:  prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
        line-height: 1.188rem;
      }
      }
      .check-align{
        margin-right: 1.5rem;
      }      
    }
  }
}
