@import "../../../assets/styles/colors.scss";

.username-wrapper {
  border-right: 0.0625rem solid var(--blue50);
  padding-right: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 1.275rem;
  margin-left: 2.5rem;

  .initials-circle {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 3.75rem;
    background-color: var(--darkGrayishBlue600);
    color: var(--white);
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
  }

  .fullname {
    color: $grayscale500;
    font-size: 0.875rem;
  }
}

@media (max-width: 991px) {
  .username-wrapper {
    margin-right: 1rem;
    padding-right: 0;
    .initials-circle {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.75rem;
      margin-right: 1rem;
    }

    .fullname {
      color: $grayscale500;
    }
  }
}
