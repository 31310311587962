.recaptcha-disclaimer {
    margin: 16px;
    font-size: .75rem;
    text-align: center;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    color: #6E6E73;
  
    a {
      color: #2F7ABF;
  
      &:hover {
        text-decoration: underline;
      }
    }
  }