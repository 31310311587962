@import '../../../assets/styles/colors.scss';

.welcome-page-express-request {
  max-width: 38rem;
  margin: 0 auto;
  .titleCheck {
    padding-bottom: 0.5rem;
  }

  .subtitle{
    text-align: center;
    color: var(--darkGrey500);
    font-size: 1.125rem;
    font-family: prelo-medium,Helvetica,Arial,Sans,Sans-serif;
    max-width: 40rem;
    line-height: 1.5rem;
    margin: 0 auto;
  }

  .steps-container {
    margin-top: 4.5rem;
  }

  .adviser-field{
    display: flex;
    justify-content: center;
  }

  .button-container {
    margin-top: 4.5rem;
  }

  .adviser {
    max-width: unset;
    width: 31rem;
    margin-bottom: -3.25rem;
  }

  .title-benefit {
    color: var(--blue500);
    font-size: 1.125rem;
    font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
    line-height: 1.318rem;
    text-align: center;
  }

  .box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

      .circle-icon {
        z-index:1;
        position:absolute;
        background: $circleColor;
        width: 3.5rem;
        height:  3.5rem;
        border-radius: 50%;
        text-align: center;
        line-height: 6.25rem;
        justify-content: center;
        vertical-align: middle;

      }
      .icon-benefit{
        margin-top: 0.25rem;
        z-index:2;
      }
    }

}

@media (min-width: 992px) {
  .builder {
    width: 14.5rem;
    margin-right: 2rem;
  }
  .agency {
    max-width: 20.25rem;
    width: 20.25rem;

    .dropdown-input {
      width: 20rem;
      padding-right: 0;
    }

  }
  .agency-first-column {
    margin-left: -3.5rem;
  }
}

@media (max-width: 61.938rem){
  .welcome-page-express-request {
    max-width: 20.5rem;
    margin: 0 auto !important;
    .titleCheck {
      padding-top: 2rem;
      max-width: 20.5rem;
      margin: 0 auto;
    }

    .subtitle {
      max-width: 20.5rem;
      font-size: 0.875rem;
    }

    .steps-container {
      margin: 0 auto;
      margin-top: 2.5rem;
    }

    .button-container {
      margin-top: -1.375rem;
      padding: 2rem 1rem;
    }

    .adviser-field{
      display: flex;
      justify-content: stretch;
      margin-top: -28px;
    }

    .adviser, .builder, .agency {
      min-width: 328px;
      width: 100%;
      margin-bottom: 20px;
      .large{
        width: calc(100% - 2.063rem);
      }
    }
  }
}