.width-adjust {
  min-width: 2rem;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  .circle-step-selected {
    background: #0f265c;
    border-radius: 3.75rem;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    color: #ffffff;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 0.75rem;
    line-height: 1.188rem;
    text-align: center;
  }
  .circle-step-disabled {
    background: white;
    border: 0.063rem solid #dbdbdc;
    border-radius: 3.75rem;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    color: #dbdbdc;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 0.75rem;
    line-height: 1.188rem;
    text-align: center;
  }
  .circle-step-checked {
    background: #31a451;
    border-radius: 2.5rem;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    color: #ffffff;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 0.75rem;
    line-height: 1.188rem;
    text-align: center;
    .icon-align {
      margin-top: 0.313rem;
    }
  }
}
.name-step-selected {
  font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  color: #0f265c;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 5.625rem;
  height: 2.5rem;
}
.name-step-disabled {
  font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  color: #dbdbdc;
  width: 5.625rem;
  height: 2.5rem;
}
.name-step-checked {
  font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  color: #4a4a50;
  width: 5.625rem;
  height: 2.5rem;
}
