@import '../../assets/styles/colors.scss';

.express-rectangle{

  &.disabled {
    background-color: var(--darkGrey50);
  }

  display: grid;
  background: white;
  border: 0.063rem solid $darkGray300;
  box-sizing: border-box;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 20rem;
  height: 8.75rem;
  padding: 0 1rem;
  align-items: center;
  text-align: center;

  &.big {
    width: calc(100% - 16px);

    .textBox-limit {
      max-width: 100%;
    }
  }
  
  .textBox-limit {
    max-width: 18.5rem;
  }
  .check-align{
    margin-right: 1.5rem;
  }
  .display{
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  
    .box-title{
      color: var(--darkGrey500);
      font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  
    .list {
      text-align: center;
            
      li {
        color: var(--darkGrey500);
        font-size: 0.75rem;
        margin: 0;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        line-height: 1.188rem;
        
      }
      .bold-number{
        font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
        font-size: 0.75rem;
        line-height: 1.188rem;
      }
      ul {
        list-style: none;
        margin: 0;
        padding-left: 0;
      }
    }
  }
}

.rectangle{
  display: grid;
  background: white;
  border: 0.063rem solid $darkGray300;
  box-sizing: border-box;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 25.5rem;
  height: 8.438rem;
  padding-left: 0;
  padding-right: 0;
  text-align: left;

  .textBox-limit{
    max-width: 18.5rem;
  }
  .check-align{
    margin-right: 1.5rem;
  }
  .display{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  
    .box-title{
      color: var(--blue500);
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1.125rem;
      line-height: 1.375rem;
    }
  
    .list {
      text-align: left;
            
      li {
        color: var(--darkGrey500);
        font-size: 0.875rem;
        margin: 0;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        line-height: 1.438rem;
        
      }
      .bold-number{
        font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
        font-size: 1rem;
        line-height: 1.625rem;
      }
      ul {
        list-style: none;
        margin: 0;
        padding-left: 1.313rem;
      }
      li::before {
        content: "\2022";
        display: inline-block;
        width: 0.75em;
        margin-left: -0.75em;
      }
    }
  }
  
}

.express-rectangle-selected {
  display: grid;
  border: 0.125rem solid #2F7ABF;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #F4F6F9;
  width: 20rem;
  height: 8.75rem;
  padding: 0 1rem;
  align-items: center;
  text-align: center;

  &.big {
    width: calc(100% - 16px);

    .textBox-limit {
      max-width: 100%;
    }
  }

  .textBox-limit{
    max-width: 18.5rem;
  }
  .check-align{
    margin-right: 1.5rem;
  }
  .display{
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  
    .box-title{
      color: var(--darkGrey500);
      font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  
    .list {
      text-align: center;
            
      li {
        color: var(--darkGrey500);
        font-size: 0.75rem;
        margin: 0;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        line-height: 1.188rem;
        
      }
      .bold-number{
        font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
        font-size: 0.75rem;
        line-height: 1.188rem;
      }
      ul {
        list-style: none;
        margin: 0;
        padding-left:0;
      }
    }
  }
}

.rectangle-selected{
  display: grid;
  border: 0.125rem solid #2F7ABF;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #F4F6F9;
  width: 25.5rem;
  height: 8.438rem;
  text-align: left;
  padding-left: 0;
  padding-right: 0;

  .textBox-limit{
    max-width: 18.5rem;
  }
  .check-align {
    margin-right: 1.5rem;
  }

  .display{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  
    .box-title{
      color: var(--blue500);
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1.125rem;
      line-height: 1.375rem;
    }
  
    .list {
      text-align: left;
            
      li {
        color: var(--darkGrey500);
        font-size: 0.875rem;
        margin: 0;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        line-height: 1.438rem;
        
      }
      .bold-number{
        font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
        font-size: 1rem;
        line-height: 1.625rem;
      }
      ul {
        list-style: none;
        margin: 0;
        padding-left: 1.313rem;
      }
      li::before {
        content: "\2022";
        display: inline-block;
        width: 0.75em;
        margin-left: -0.75em;
      }
    }
  }
}

.express-rectangle,
.express-rectangle-selected {
  &.linkbox {
    .linkbox-body {

      .linkbox-info {
        cursor: pointer;
      }

      .linkbox-link {
        cursor: pointer;
        font-size: .875rem;
        color: #2F7ABF;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (max-width: 61.938rem){
 
  .rectangle{
    width: 20.5rem;
    height: auto;
    .textBox-limit{
      max-width: 18.5rem;
    }
    .check-align{
      margin-right: 1.5rem;
    }
    .display{
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
    
      .box-title{
        color: var(--blue500);
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        font-size: 1rem;
        line-height: 1.625rem;
      }
    
      .list {
        text-align: left;
              
        li {
          color: var(--darkGrey500);
          margin: 0;
          font-size: 0.75rem;
          font-family:  prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
          line-height: 1.188rem;
          
        }
        .bold-number{
          font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
          font-size: 0.75rem;
          line-height: 1.188rem;
        }
        ul {
          list-style: none;
          margin: 0;
          padding-left: 1.313rem;
        }
        li::before {
          content: "\2022";
          display: inline-block;
          width: 0.75em;
          margin-left: -0.75em;
        }
      }
    }
    
  }
  .rectangle-selected{
    width: 20.5rem;
    height: auto;
    .textBox-limit{
      max-width: 18.5rem;
    }
    .check-align{
      margin-right: 1.5rem;
    }
  
    .display{
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
    
      .box-title{
        color: var(--blue500);
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        font-size: 1rem;
        line-height: 1.625rem;
      }
    
      .list {
        text-align: left;
              
        li {
          color: var(--darkGrey500);
          margin: 0;
          font-family:  prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
          font-size: 0.75rem;
          line-height: 1.188rem;
          
        }
        .bold-number{
          font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
          font-size: 0.75rem;
          line-height: 1.188rem;
        }
        ul {
          list-style: none;
          margin: 0;
          padding-left: 1.313rem;
        }
        li::before {
          content: "\2022";
          display: inline-block;
          width: 0.75em;
          margin-left: -0.75em;
        }
      }
    }
  }

  .express-rectangle{
    display: grid;
    background: white;
    border: 0.063rem solid $darkGray300;
    box-sizing: border-box;
    border-radius: 0.5rem;
    cursor: pointer;
    width: 20.3rem;
    height: 8.75rem;
    padding: 0 1rem;
    align-items: center;
    text-align: center;
    .textBox-limit{
      max-width: 18.5rem;
    }
    .check-align{
      margin-right: 1.5rem;
    }
    .display{
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
    
      .box-title{
        color: var(--darkGrey500);
        font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    
      .list {
        text-align: center;
              
        li {
          color: var(--darkGrey500);
          font-size: 0.75rem;
          margin: 0;
          font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
          line-height: 1.188rem;
          
        }
        .bold-number{
          font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
          font-size: 0.75rem;
          line-height: 1.188rem;
        }
        ul {
          list-style: none;
          margin: 0;
          padding-left: 0;
        }
      }
    }
    
  }
  .express-rectangle-selected{
    display: grid;
    border: 0.125rem solid #2F7ABF;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #F4F6F9;
    width: 20.5rem;
    height: 8.75rem;
    padding: 0 1rem;
    align-items: center;
    text-align: center;
    .textBox-limit{
      max-width: 18.5rem;
    }
    .check-align{
      margin-right: 1.5rem;
    }
    .display{
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
    
      .box-title{
        color: var(--darkGrey500);
        font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    
      .list {
        text-align: center;
              
        li {
          color: var(--darkGrey500);
          font-size: 0.75rem;
          margin: 0;
          font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
          line-height: 1.188rem;
          
        }
        .bold-number{
          font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
          font-size: 0.75rem;
          line-height: 1.188rem;
        }
        ul {
          list-style: none;
          margin: 0;
          padding-left:0;
        }
      }
    }
  }

  .express-rectangle,
  .express-rectangle-selected {
    &.linkbox {
      width: 100%;
    }
  }
}

