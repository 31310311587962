.align-step {
  margin: 0 auto;
  max-width: 42rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 5.5rem;
  position: relative;
  z-index: 1;
  &:before {
    border-top: 0.063rem solid #dbdbdc;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 81.5%;
    z-index: -1;
  }
}

@media (max-width: 61.938rem){
  .align-step {
    max-width: 18rem;
    &:before {
      top: 76% !important;
    }
  }
}