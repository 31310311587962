.calendar-position {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  &__init {
    width: 100%;
  }
  &__end {
    width: 100%;
  }
  @media (min-width: 48rem) {
    flex-direction: row;
  }
  @media (min-width: 62rem) {
    width: 97%;
  }
}
