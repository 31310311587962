.step-number {
    display: flex;
    margin-left: -60px;
    border: solid 20px white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: white;
}

@media (max-width: 1200px) {
    .step-number {
        display: flex;
        margin-left: -25px;
        border: solid 20px white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        color: white;
    }
}