.sidebar-container {

  .divider {
    border-bottom: 0.0625rem solid var(--darkGrayishBlue300);
    padding-bottom: 2rem;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    a,
    a:visited,
    a:hover,
    a:active {
      color: inherit;
    }
    .item-sidebar {
      font-size: 1.125rem;
      color: var(--blue300);
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      margin-top: 1.5rem;

      a {
        &.active {
          color: var(--blue500);
          font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
        }
      }
    }
  }

}

.sidebar-button-text {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    margin-right: 0.75rem;
    margin-top: 0.3rem;
  }

}

.callback-sidebar {
  padding-right: 3.5rem;
  .callback-content {
    margin-top: 1.5rem;
    padding: 1rem;
    border: 0.0625rem solid var(--darkGrayishBlue300);

    .text-title {
      font-size: 1rem;
      margin-top: 0;
    }

    .text-content {
      font-size: 0.75rem;
    }
  }
}

.mortgage-guide {
  max-width: 90rem;
  margin: 0 auto;
  .hero {
    display: flex;
    align-items: center;
    margin-top: 5rem;
    height: 15.625rem;
    background: url("../../assets/img/img-header-guia-hipotecario-desktop.jpg")
      right no-repeat;
    background-size: 50% auto;
    background-color: var(--darkGrayishBlue100);

    .guide-title {
      width: 64rem;
      margin: 0 auto;
    }
  }

  .section-title {
    .main-title {
      font-size: 1.75rem;
    }
  }

  .guide-content {
    max-width: 64rem;
    margin: 0 auto;
  }

  #introduccion {
    p {
      margin: 0;
      font-weight: normal;
    }
  }

  #amortizaciones {
    .action-text {
      a {
        color: var(--strongBlue500);
      }
    }
  }

  .benefits {
    margin-top: 2.5rem;
    .title-benefit,
    .subtitle-benefit,
    .icon-benefit {
      margin-top: 0.35rem;
      padding: 0;
      text-align: center;
      width: 100%;
    }

    &.column {
      margin-top: 0.75rem;
      flex-direction: column;
      flex-wrap: nowrap;

      .box-container {
        display: flex;
        margin-right: 0;
        .circle-icon {
          z-index: -2;
          position: absolute;
          background: #f4f6f9;
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
          text-align: center;
          line-height: 6.25rem;
          vertical-align: middle;
        }
        .icon-benefit {
          margin-top: 0.75rem;
          margin-left: 0.5rem;
        }
        .box {
          padding-top: 0.5rem;
          margin-right: 2rem;
        }
        .boxOrder {
          max-width: inherit;
          .title-benefit {
            margin-bottom: 0.5rem;
          }
          .subtitle-benefit {
            margin: 0;
            max-width: 30rem;
          }

          .title-benefit,
          .subtitle-benefit {
            text-align: left;
          }
        }

        .title-benefit {
          margin-top: 0.5rem !important;
        }
      }
    }
  }

  .section-subtitle {
    font-size: 1.25rem;
    color: var(--blue500);
  }

  .checklist {
    padding-left: 1.5rem;
    .element {
      margin-top: 0.5rem;
      display: flex;

      .icon {
        margin-right: 1.125rem;

        .circle-container {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 2.5rem;
          height: 2.5rem;
          background-color: var(--white);
        }
      }

      .text {
        strong {
          color: var(--blue500);
        }
      }
    }
  }

  .message-box-row {
    display: flex;
    justify-content: space-between;
  }

  .message-box {
    background-color: var(--darkGrayishBlue100);
    border-radius: 0.25rem;
    padding: 1rem;

    .title {
      margin-top: 0;
    }

    .list {
      margin-top: 1rem;
      text-align: left;

      li {
        color: var(--darkGrey500);
        font-size: 0.875rem;
        margin: 0;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        line-height: 1.438rem;
      }
      ul {
        list-style: none;
      }
      li::before {
        content: "\2022";
        display: inline-block;
        width: 0.75em;
        margin-left: -0.75em;
      }
    }

    &.double-box {
      padding-top: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 0;
      height: 10rem;
    }
  }
}

@media (min-width: 61.939rem) {
  .mortgage-guide {
    .sidebar-container {
      padding-right: 3.5rem;
    }

    .benefits {
      display: flex;
      flex-wrap: wrap;

      .box-container {
        margin-right: 3.5rem;
      }

      .boxOrder {
        max-width: 175px;
        .box {
          margin-bottom: 0.5rem;
          align-items: flex-start;

          .icon-benefit {
            margin-left: 0.75rem;
          }
        }
      }

      .title-benefit,
      .subtitle-benefit,
      .icon-benefit {
        text-align: left;
        width: 100%;
      }

      .title-benefit {
        margin-top: 1.5rem;
      }

      &.column {
        .box-container {
          .icon-benefit {
            margin-left: 0.75rem;
          }
          .box {
            padding-top: 2rem;
          }
        }
      }
      
    }

    .message-box {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    #amortizaciones {
      .message-box {
        margin-left: 1rem !important;
      }
      .action-text {
        margin-top: 1.5rem;
        margin-left: 1rem;
        margin-bottom: 3.5rem;
      }
    }
  }
}

@media (max-width: 61.938rem) {
  .mobile-width {
    max-width: 22.5rem;
    margin: 0 auto;
  }

  .mortgage-guide {
    .hero {
      margin-top: 3.5rem;
      background: none;
      background-color: var(--darkGrayishBlue100);
      .guide-title {
        padding: 2rem 0;
        max-width: 20.5rem;
      }
    }

    .benefits {
      .boxOrder {
        .box {
          margin-bottom: 0.5rem;
        }
      }
      &.column {
        margin-top: 1.5rem;
      }
    }

    .message-box-row {
      display: block;

      .message-box {
        margin-top: 1rem;
        margin-right: 0 !important;
        margin-left: 0 !important;
        padding-bottom: 1rem;

        .text-title {
          margin-top: 0;
        }
        &.double-box {
          padding-top: 1rem;
          padding-bottom: 0;
          height: 10rem;

        }
      }
    }

    #amortizaciones {
      .action-text {
        margin-top: 1rem;
        margin-bottom: 7.25rem;
      }
    }

    .cta-secondary {
      .card {
        padding-left: 0;
        padding-right: 0;
      }
      .buttons {
        margin: 0 auto;
        max-width: 11.875rem;
      }
    }

  }
  .article {
    border-left: none !important;
    padding-top: 0.5rem !important;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
}

main {
  display: flex;
  flex-wrap: wrap;
  max-width: 64rem;
  margin: 0 auto;
}

.article {
  flex-basis: 0;
  flex-grow: 999;
  min-width: 40%;
  border-left: 0.0625rem solid var(--darkGrayishBlue300);
  padding-top: 2.5rem;
}

.sidebar {
  --offset: 2rem;
  flex-grow: 1;
  flex-basis: 20rem;
  align-self: start;
  position: sticky;
  top: 5rem;

  .component {
    max-height: calc(100vh - var(--offset) * 2);
    margin-top: 2.5rem;
  }
}
