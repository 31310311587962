.ldpd-error {
  text-align: -webkit-center;
  margin: 0 -2px;

  &__last-text {
    border-bottom: 0.063rem solid var(--darkGrey100);
  }

  &__button {
    width: 196px;
  }
}
