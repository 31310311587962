.mt-5 {
  padding-top: 5rem;
  .left-block-express {
    background-image: url("../../../assets/img/img-express-background.svg");
    background-size: cover;
    min-height: calc(100vh - 9rem);
  }
}

.image-side {
  position: fixed;
}

.contain-size {
  max-width: 90rem;
  margin: 0 auto;
  .box-contain {
    max-width: 60.5rem;
    width: 77%;
    min-height: 51.25rem;
    margin-top: 5rem;
    margin-bottom: 1.875rem;
    align-items: center;
    position: relative;
    margin-left: 35rem;

    &.welcome-page {
      max-width: 100%;
      width: 100%;
      margin-bottom: 1.875rem;
      margin-left: 0;
      min-height: 0;
    }
    &.form-inputs {
      align-items: flex-start;
    }

    @media only screen and (min-width: 992px) {
      .step-page {
        max-width: 42rem;
      }
    }
  }
}
