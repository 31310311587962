.steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: stretch;
    max-width: 90rem;
    margin: 0 auto;
    scroll-margin: 2.5rem;
    &__img {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__msgs-container {
        display: flex;
        flex-direction: column;
        &__title {
            padding: 3.563rem 0 1.5rem 0;
            margin-left: -1rem;
        }
        &__flex {
            display: flex;
            &__steps {
                padding-left: 20px;
                margin-right: 20px;
                &__step-section {
                    height: 10.5rem;
                    width: 32.25rem;
                    display: "flex";
                    &__title {
                        display: flex;
                        flex-direction: row;
                        &__step_text {
                            margin-top: 23px;
                            margin-left: -5px;
                        }
                    }
                    &__content-msg {
                        margin: -25px 35px 0 15px;
                    }
                }
            }
            &__steps-line {
                border-left: solid 0.063rem #dee3ed;
                height: 45rem;
                width: 1px;
            }
        }
        &__buttons {
            width: 77%;
            display: flex;
            justify-content: space-around;
            margin-left: -25px;
            margin-bottom: 56px;
            &__desk-button-left {
                width: 192px;
            }
            &__desk-button-right {
                width: 192px;
            }
        } 
    }
}

.link-bp {
    color: #276db6;
    &:hover {
        cursor: pointer;
    }
}

@media (max-width: 1200px) {
    .steps-container {
        scroll-margin: 6.5rem;
        display: block;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: stretch;
        max-width: 67.5rem;
        margin: 0 auto;
        &__msgs-container {
            display: block;
            flex-direction: column;
            &__title {
                padding: 0px 24px;
                margin-left: 0px;
                width: 90%;
                text-align: center;
                margin-top: 48px;
            }
            &__flex {
                display: block;
                &__steps {
                    padding-left: 0px;
                    margin:  24px 100px;
                    &__step-section {
                        margin-bottom: 32px;
                        width: 100%;
                        height: auto;
                        display: block;
                        &__title {
                            display: block;
                            flex-direction: row;
                            &__step_text {
                                margin-top: 0px;
                                margin-left: 0px;
                            }
                        }
                        &__content-msg {
                            margin: 16px 0;
                        }
                    }
                }
            }
            &__buttons {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-left: 0px;
                &__desk-button-left {
                    width: 90%;
                    margin-bottom: 16px;
                }
                &__desk-button-right {
                    width: 90%;
                }
            } 
        }
    }
}


@media (max-width: 500px) {
    .steps-container {
        &__msgs-container {
            &__flex {
                &__steps {
                    padding-left: 0px;
                    margin: 24px;
                }
            } 
        }
    }
}