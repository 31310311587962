@import "../../../assets/styles/colors.scss";

.link {
  cursor: pointer;
  padding-top: 2.3rem;
  padding-bottom: 2rem;
  display: flex;

  .icon-container {
    margin-right: 0.5rem;
    margin-top: 0.075rem;
  }

  .text {
    cursor: pointer;
    color: var(--blue300);
    text-decoration: underline;
  }
}

.title-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 53rem;
  margin: 0 auto;

  .titleCheck {
    text-align: left;
    font-size: 1.75rem;
    margin: 0 auto;

  }
}

.results-container {
  max-width: 53rem;
  margin: 0 auto;

  .list {
    margin-top: 1rem;
    text-align: left;

    li {
      color: var(--darkGrey500);
      font-size: 0.75rem;
      margin: 0;
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      line-height: 1.188rem;
      letter-spacing: 0;
    }
    ul {
      list-style: none;
    }
    li::before {
      content: "\2022";
      display: inline-block;
      width: 0.75em;
      margin-left: -0.75em;
    }
  }
}

.grafica-container {
  height: 30.5rem;
  display: flex;
  justify-content: center;

  .graphic-display {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    .size-graphic {
      max-width: 20rem;
      max-height: 20rem;
      align-items: center;
      justify-content: center;
    }

    .circle-title {
      width: 11rem;
      height: 11rem;
      background: white;
      position: absolute;
      border-radius: 11.563rem;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .title-result {
        font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
        font-size: 0.875rem;
        line-height: 1.438rem;
        color: $blue500;
        padding-bottom: 0.5rem;
        width: 9rem;
        text-align: center;
      }
      .monthly-fee {
        font-family: preloslab-semibold, Helvetica, Arial, Sans, Sans-serif;
        font-size: 1.5rem;
        line-height: 1.813rem;
        color: $blue500;
        width: 9rem;
        text-align: center;
      }
    }
  }

  .totals {

    border-bottom: 0.063rem solid var(--darkGrayishBlue300);
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;

    .row {
      display: flex;
      justify-content: space-between;

      .bullet {
        display: flex;
        align-items: center;

        .dot {
          border-radius: 50%;
          width: 0.875rem;
          height: 0.875rem;

          &.yellow {
            background: var(--yellow500);
          }

          &.blue-500 {
            background: var(--blue500);
          }

          &.blue-300 {
            background: var(--blue300);
          }

        }

      }

    }
  }
}

.result-card {
  // max-width: 20rem;
  border-radius: 0.25rem;

  .accordion {
    height: 3.5rem;
    background-color: $darkGrayishBlue50;
    color: var(--blue500);
    cursor: pointer;
    padding: 1.2rem 1.375rem 1rem 1rem;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: max-height 0.2s ease-out;
    font-size: 1.25rem;
    font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;

    &.desktop-card {
      &::after,
      &::before {
        content: "";
      }
    }

    &:after {
      content: url('../../../assets/icons/expand-more.svg');
      color: $blue500;
      font-weight: bold;
      float: right;
      margin-left: 0.313rem;
      font-family: monospace;
      font-size: 1.4rem;
    }

    &.active {
      &:after {
        content: url('../../../assets/icons/expand-less.svg');
      }
    }
  }

  .panel {
    height: 11rem;
    padding: 0 1rem;
    background-color: $darkGrayishBlue50;
    display: none;

    .has-separator {
      border-bottom: 0.063rem dotted var(--blue200);
    }

    .register {
      display: flex;
      justify-content: space-between;
    }

    &.mortgage-detail {
      height: auto;
    }

    &.total-values {
      height: 7.25rem;
    }

  }

  .desktop {
    .panel {
      display: block;
    }
  }
}

@media (max-width: 992px) {
  .result-card {
    max-width: 20.5rem;
    margin: 0 auto;

    .accordion {
      font-size: 1.125rem;
      font-weight: bold;
    }
  }

  .results-container {
    max-width: 20.5rem;
  }

  .title-container {
    max-width: 21.5rem;
    justify-content: center;

    .titleCheck {
      text-align: center;
      padding-bottom: 1rem;
      margin-top: 5rem;
    }

    .link {
      padding-top: 0;
    }
  }
}
