body {
  font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (min-width: 1200px) {
  .desktop-large {
    display: block !important;
  }
  .mobile-large {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .mobile {
    display: none !important;
  }
  .desktop {
    display: block;
  }
  .separator {
    border-bottom: 0.063rem solid var(--darkGrayishBlue300);
    padding-top: 2rem;
    max-width: 54rem;
    margin: 0 auto;
  }
}

@media (max-width: 1199px) {
  .desktop-large {
    display: none !important;
  }
  .mobile-large {
    display: block !important;
  }
}

@media (max-width: 991px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none !important;
  }
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.page-content {
  flex: 1 auto;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  min-height: 100vh;
  overflow-x: hidden;
  &.fit-footer {
    display: flex;
    justify-content: space-between;
  }
}

.link-bp:hover{
  cursor: pointer;
}

.flex-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

/*Animations*/

.animated {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-duration: 1s;
  animation-fill-mode: both;
  transition-delay: 0.6s;
  transition-duration: 0.4s;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.animated.fast {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  transition-delay: 0.4s;
  transition-duration: 0.4s;
}
.animated.faster {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  transition-delay: 0.1s;
  transition-duration: 0.4s;
}
.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  transition-delay: 1.6s;
  transition-duration: 0.4s;
}
.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  transition-delay: 2.6s;
  transition-duration: 0.4s;
}

@-webkit-keyframes fadeInLeftSmall {
  0% {
    -webkit-transform: translate3d(-10%, 0, 0);
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes fadeInLeftSmall {
  0% {
    -webkit-transform: translate3d(-10%, 0, 0);
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    opacity: 1;
    transform: translateZ(0);
  }
}
.fadeInLeftSmall {
  -webkit-animation-name: fadeInLeftSmall;
  animation-name: fadeInLeftSmall;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes fadeInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    opacity: 1;
    transform: translateZ(0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes fadeInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    opacity: 1;
    transform: translateZ(0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInRightSmall {
  0% {
    -webkit-transform: translate3d(10%, 0, 0);
    opacity: 0;
    transform: translate3d(10%, 0, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes fadeInRightSmall {
  0% {
    -webkit-transform: translate3d(10%, 0, 0);
    opacity: 0;
    transform: translate3d(10%, 0, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    opacity: 1;
    transform: translateZ(0);
  }
}
.fadeInRightSmall {
  -webkit-animation-name: fadeInRightSmall;
  animation-name: fadeInRightSmall;
}
