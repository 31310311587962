@import '../../../assets/styles/colors.scss';


.calculation-results-buttons {
  margin-bottom: 3.25rem;
}

.section-container-mobile {
  max-width: 53rem;
  margin: 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 2rem;
  display: none;

  .large-button {
    min-width: 14.5rem;
    margin: 0 auto;
  }
}

.section-container-desktop {
  max-width: 53rem;
  margin: 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 2rem;

  .large-button {
    min-width: 14.5rem;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 61.938rem) {
  .section-container-mobile {
    max-width: 20.5rem;
    margin: 0 auto;
    padding-top: 0.75rem;
    padding-bottom: 1.5rem;

    display: block;
    .large-button {
      min-width: 20.5rem;
      margin: 0 auto;
    }
    .list {
      margin-top: 2rem;
      text-align: left;

      li {
        color: var(--darkGrey500);
        font-size: 0.75rem;
        margin: 0;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        line-height: 1.188rem;
        letter-spacing: 0;
      }
      ul {
        list-style: none;
        padding-left: 1rem;
      }
      li::before {
        content: "\2022";
        display: inline-block;
        width: 0.75em;
        margin-left: -0.6rem;
      }
    }
  }

  .section-container-desktop {
    max-width: 20.5rem;
    margin: 0 auto;
    padding-top: 0.75rem;
    padding-bottom: 2rem;
    display: none;
    .large-button {
      min-width: 20.5rem;
      margin: 0 auto;
    }
  }
}
