@import "../../assets/styles/breakpoints.scss";

.none {
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: rgba(74, 74, 80, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  &__box {
    min-height: 363px;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    &--sm {
      min-height: 382px;
      width: 472px;
      max-height: calc(100vh - 80px);
    }
    &--md {
      width: 688px;
    }
    &--lg {
      width: 904px;
    }
  }
  &__header {
    min-height: 56px;
    border-radius: 8px 8px 0px 0px;
    position: relative;
    &--border {
      box-shadow: 0px 8px 8px -8px rgba(7, 7, 7, 0.16);
    }
    &--close {
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 16px;
      visibility: visible;
    }
  }
  &__body {
    min-height: 150px;
    max-height: calc(100vh - 377px);
    margin: 40px 7px 40px 31px;
    padding: 0 24px 0 0;
    overflow-y: overlay;
    &::-webkit-scrollbar {
      width: 8px;
      height: 100%;
      border-radius: 4px;
      background: #e9ecf3;
    }
    &::-webkit-scrollbar-thumb {
      height: 44px;
      border-radius: 5px;
      background: #b7b7b9;
    }
  }
  &__footer {
    border-top: 1px solid rgb(236, 236, 236);
    display: flex;
    justify-content: flex-end;
    &--sm,
    &--md,
    &--lg {
      padding: 24px 29px;
    }
  }
  &__button {
    &--sm,
    &--md,
    &--lg {
      width: auto;
      &:nth-child(2) {
        margin-left: 16px;
      }
    }
    &--long {
      width: 196px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .modal {
    &__box {
      &--sm {
        width: 396px;
      }
      &--md {
        min-height: 373px;
        width: 580px;
      }
      &--lg {
        width: 580px;
      }
    }
    &__body {
      margin: 30px 16px 30px 32px;
      padding: 0 16px 0 0;
      &--md,
      &--lg {
        margin: 40px 8px 40px 24px;
        padding: 0 16px 0 0;
      }
    }
    &__footer {
      &--sm,
      &--md,
      &--lg {
        padding: 24px;
      }
    }
    &__button {
      &--sm {
        width: auto;
      }
      &--md,
      &--lg {
        width: auto;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .modal {
    align-items: flex-end;
    &__box {
      max-height: 100%;
      width: 100% !important;
      border-radius: 16px 16px 0px 0px;
    }
    &__body {
      max-height: calc(100vh - 286px);
      margin: 24px 2px 24px 16px;
      padding: 0 14px 0 0;
      &--hb {
        max-height: calc(100vh - 100px);
      }
    }
    &__footer {
      display: flex;
      flex-direction: column-reverse;
      &--sm,
      &--md,
      &--lg {
        padding: 16px;
      }
    }
    &__button {
      &--sm,
      &--md,
      &--lg {
        width: 100% !important;
        margin-left: 0px !important;
        &:nth-child(1) {
          margin-top: 16px;
        }
      }
    }
  }
}
