@import "../../assets/styles/colors.scss";

.container-message {
  position: fixed;
  width: 38rem;
  padding: 1rem 1rem;
  color: $darkGray500;
  border-radius: 0.5rem;
  box-sizing: border-box;

  font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;

  .align-message-bar {
    display: flex;
    justify-content: space-between;
    box-sizing: content-box;
    text-align: left;
    align-items: flex-start;
    .icon-close{
      cursor: pointer;
    }
    img {
      margin-top: 0.35rem;
    }
    .text-error {
      font-size: 1rem;
    }

    .icon-and-message {
      display: flex;
      justify-content: flex-start;
    }

  }
  .text-error {
    margin-left: 0.5rem;
  }

  &--normal {
    &--error {
      background-color: var(--danger);
    }

    &--success {
      background-color: var(--success);
    }

    &--warning {
      background-color: #fcc399;
    }

    &--info {
      background-color: var(--info);
    }
  }

  &--light {
    &--error {
      background-color: #fbe6e6;
      border: 1px solid var(--danger);
    }

    &--success {
      background-color: #ecf6ee;
      border: 1px solid var(--success);
    }

    &--warning {
      background-color: #fef0e5;
      border: 1px solid #ffa000;
    }

    &--info {
      background-color: #eaf1f8;
      border: 1px solid var(--info);
    }
  }

  &__iconClose {
    cursor: pointer;
    margin: 0px 15px 0px 5px;
  }

  &__iconMessage {
    align-self: center;
    margin: 0px 8px;
  }
}

@media only screen and (max-width: 61.938rem) {
  .container-message {
    max-width: 25.5rem !important;
  }
}
