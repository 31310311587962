@import "../../assets/styles/colors.scss";

.living-places {
  .container-link {
    text-align: -webkit-center;
    .more-products-link {
      margin-top: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: max-content;
      pichincha-icon {
        transform: translateY(3px);
      }
      pichincha-typography {
        z-index: -1;
      }
    }
  }
  .living-places-body {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: stretch;
    max-width: 67.5rem;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 650px) {
      margin-bottom: 48px;
    }

    .toggle-details {
      display: flex;
      justify-content: right;
      cursor: pointer;
    }

    .percentage-box {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    .title {
      font-weight: normal;
      font-size: 1.75rem;
      margin: 0;
      font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
    }

    @media (max-width: 991px) {
      .title {
        font-size: 1.5rem;
        margin: 0;
        font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
        font-weight: normal;
        padding: 0 0 1rem;
        line-height: 1.813rem;
        text-align: left;
      }
    }

    .card:hover {
      box-shadow: 0px 0px 4px rgba(7, 7, 7, 0.16),
        0px 8px 8px -8px rgba(7, 7, 7, 0.16);
    }
    .card {
      background-color: var(--white);
      border: 0.063rem solid var(--blue100);
      box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(7, 7, 7, 0.16);
      border-radius: 0.25rem;
      max-width: 32.25rem;

      .body {
        padding: 2rem;

        hr {
          border: 1px solid var(--darkGrayishBlue300);
          margin-top: 1.438rem;
          margin-bottom: 2.469rem;
        }

        .title {
          font-weight: normal;
          font-size: 1.5rem;
          font-family: preloslab-semibold, Helvetica, Arial, Sans, Sans-serif;
          margin-bottom: 0;
          margin-top: 0;

          &--secondary {
            color: var(--blue500);
            font-size: 1rem;
            font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
            margin-top: 1.5rem;
          }
        }

        .content {
          color: var(--darkGrey500);
          font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
          line-height: 1.625rem;
          margin-bottom: 1rem;
          margin-top: 0.5rem;
        }

        .link {
          display: flex;
          font-size: 0.875rem;
          font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
          align-items: center;
          justify-content: center;
          color: var(--blue500);
          margin-bottom: 0;
          .arrow {
            margin-left: 0.35rem;
            margin-top: 0.5rem;
            font-size: 1.5rem;
          }
        }
      }

      .button-section {
        padding: 0 2rem 2rem 2rem;
      }
    }

    @media (max-width: 991px) {
      .card {
        margin: 0 auto;
        margin-top: 1rem;
        .body {
          .title {
            margin: 0rem;
            padding-bottom: 0.5rem;
            &--secondary {
              margin-top: 1rem;
              font-size: 0.875rem;
              line-height: 1.438rem;
              margin-bottom: 0.5rem;
            }
          }
          .content {
            margin: 0 0 1rem 0;
          }
        }
      }
    }

    @media (max-width: 991px) {
      .card {
        margin-left: 1rem;
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    @media (max-width: 480px) {
      .card {
        margin: 1.5rem !important;
        .body {
          padding: 1.5rem 1.5rem 1rem 1.5rem;
          hr {
            margin-top: 0.969rem;
            margin-bottom: 1.469rem;
          }
        }
        .button-section {
          padding: 0 1.5rem 1.5rem 1.5rem;
        }
      }
    }

    @media (min-width: 992px) {
      .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  @media (max-width: 991px) {
    .living-places-body {
      display: block;
      margin-top: 2.5rem;
      .card {
        margin: 0 auto;
        margin-top: 1.5rem;
      }
    }
  }

  @media (max-width: 1024px) and (min-width: 1024px) {
    .living-places-body {
      max-width: 59rem;

      .card {
        max-width: 28.7rem;
      }
    }
  }
}
