.container-lpdp {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__link {
      color: var(--info);
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-top: 0.063rem solid var(--darkGrey100);
    @media (min-width: 48rem) {
        flex-direction: row;
    }
    &__accept {
        width: 100%;
        @media (min-width: 48rem) {
            width: 200px;
        }
    }
  }
}

.checkbox-container {
  .checkbox-label {
    color: #6e6e73;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0 !important;
    padding-top: 0.3rem !important;

    display: inline-block;
    text-align: left;
    margin-left: 1.5rem;
  }
}

.info-error {
   padding-left: 0.2rem;
   padding-top: 0.5rem;
   display: flex;
   flex-direction: row;
    span{
        color: #D50707;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        font-size: 0.75rem;
        margin-top: 0.1rem !important;
    
        display: inline-block;
        text-align: left;
        margin-left: 0.3rem;
    }
}
