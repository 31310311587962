.checkbox-container {
  position: relative;

  &--slot {
    display: inline-block;
    margin-left: 1.75rem;
  }

  input[type="checkbox"] {
    width: 0.938rem;
    height: 0.938rem;
    color: black;
    border: 0.063rem solid var(--darkGrey400);
    border-radius: 0.25rem;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    position: absolute;
    margin-top: 0.25rem !important;
    &:hover {
      border: 0.125rem solid var(--darkGrey500);
    }
    &:focus {
      border: 0.125rem solid var(--blue500);
    }

    &:checked {
      color: var(--white);
      border-color: var(--blue500);
      background: var(--blue500);
      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
      &:hover,
      &:focus {
        border: 0.063rem solid var(--blue500);
      }

      &::before {
        position: absolute;
        content: "";
        opacity: 1;
        display: block;
        left: 3px;
        top: 0px;
        width: 4px;
        height: 8px;
        border-style: solid;
        border-color: var(--white);
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    &:disabled {
      color: var(--white);
      border-color: var(--darkGrey300) !important;
      background: var(--white) !important;
    }

    &.error {
      border-color: var(--danger);
      border-width: 1px;
    }
  }
}
