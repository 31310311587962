@import "../../assets/styles/colors.scss";
$cAccent: #3978EF;
$cBlack: #121212;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);

.cbb-wrapper:focus-visible {
  outline: none;
}

.cbb-wrapper {

  .modal {
    position: relative;

    .modal__button--sm {
      width: 100%;
    }

    &__body {
      .title {
        color: $grayscale500;
        font-size: 20px;
        line-height: 24px;  
        margin-bottom: 0.5rem;
      }
      .subtitle {
        color: $grayscale500;
        font-size: 14px;
        line-height: 20px;  
        margin-bottom: 1rem;
        padding: 0 1rem;
      }

      .text-label {
        color: $grayscale500;
      }
      .phone-wrapper {
        border-left: 0.063rem solid var(--darkGrey200);
        border-radius: 0.25rem 0 0 0.25rem;

        .prefix-num {
          height: 50px;
          width: 48px;
        }
      }
      .error {
        .prefix-num {
          border-color: var(--danger);
          background-color: var(--danger50);
        }
      }
    }

    &__footer {
      align-items: center;
      flex-direction: column;

      .disclaimer {
        font-size: .75rem;
        text-align: center;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        color: $grayscale400;
        margin-bottom: 1rem;
        line-height: 16px;
      }

      .modal__button--sm {
        margin: 0;
      }

    }

  }

  &--opened {
    width: 100%;
    height: 100%;
    background: rgba(74, 74, 80, 0.8);
    bottom: 0;
      right: 0;
  }

  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 20000;

  .cbb-list {

    &--opened,
    &--closed {
      
      
      list-style: none;
      margin: 0;
      padding: 0;
      background-color: #fff;
      box-shadow: 0px 0px 4px rgba(7, 7, 7, 0.16), 0px 12px 12px -8px rgba(7, 7, 7, 0.16);
      border-radius: 8px;
      overflow: hidden;
    }

    .cbb-item {
      &__line {
        cursor: pointer;
        padding: 14px 16px 16px 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #0F265C;
        z-index: 2;
        position: relative;
      }
      
      &__title {
        cursor: pointer;
        margin: 0;    
        font-size: 0.875rem;
        color: white;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      }
    
      &__call {
        position: absolute;
        top: 0;
        left: 4px;
        width: 48px;
        height: 48px;
        background-image: url('../../assets/icons/icon-call.svg');
      }
      
      &__arrow-down {
        opacity: 0;
        margin-top: 2px;
        width: 10px;
        height: 7px;
        transition: transform 0.3s ease-in-out;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../assets/icons/icon-arrow.png');
      }
      
      &__inner {
        max-height: 0;
        overflow: hidden;
        text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
        transition-duration: 0.4s;
        transition-property: max-height;
        z-index: 1;
        position: relative;
      }
      
      &__content {
        opacity: 0;
        transition-timing-function: linear, ease;
        transition-duration: 0.4s;
        transition-property: opacity, transform;
        transition-delay: 0.5s;
      }
      
      &__paragraph {
        margin: 0;
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        color: #2C2C30;
        font-size: 0.75rem;
      }
    
      &__link {
        margin-top: 8px;
      }
    }

    &--opened {
      position: absolute;
      bottom: 40px;
      right: 40px;
      max-width: 350px;
      border-radius: 8px;
      transition-duration: 0.4s;
      transition-property: max-width, border-radius;
      @media (max-width:500px) {
      max-width: 320px;
      }

      .cbb-item {
        &__call {
          transform: translate(16px, 16px);
          height: 0;
          transition-delay: 0.2s;
          transition-duration: 0.4s;
          transition-property: transform, heigth;
        }
    
        &__arrow-down {
          opacity: 1;
          transition-delay: 0.4s;
          transition-duration: 0.4s;
          transition-property: opacity;
        }
    
        &__line {
          background-color: white;
          padding: 0;
          visibility: hidden;
          height: 0;
          transition-delay: 0.2s;
          transition-duration: 0.2s;
          transition-property: background-color, padding, visibility;
        }
    
        &__title {
          color: #2C2C30;
          font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
          height: 0;
          transition-delay: 0.2s;
          transition-duration: 0.4s;
          transition-property: color, font-family, height;
        }
    
        &__inner {
          max-height: 100rem;
          transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
          transition-delay: 0.2s;
          transition-duration: 0.4s;
          transition-property: max-height;
        }
        
        &__content {
          opacity: 1;
          transform: translateY(0);
          transition-delay: 0.2s;
          transition-duration: 0.4s;
          transition-property: opacity, transform;
        }
      }
    }

    &--closed {
      max-width: 185px;
      border-radius: 30px;
      transition-delay: 0.4s;
      transition-duration: 0.4s;
      transition-property: max-width, border-radius;

      .cbb-item {
        &__call {
          transform: translate(0) scale(0.8);
          transition-duration: 0.4s;
          transition-property: transform;
        }
    
        &__arrow-down {
          opacity: 0;
          transition-duration: 0.4s;
          transition-property: opacity;
        }
    
        &__line {
          background-color: #0F265C;
          padding: 14px 14px 16px 48px;
          visibility: visible;
          transition-duration: 0.2s;
          transition-property: background-color, padding, visibility;
        }
    
        &__title {
          color: white;
          font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
          transition-duration: 0.4s;
          transition-property: color, font-family;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .cbb-wrapper {

    &--closed {
      bottom: 20px;
      right: 20px;
    }

    .cbb-list {
      &--opened {
        bottom: 20px;
        right: 20px;
      }
      &--closed {
        max-width: 48px;
  
        .cbb-item {
          &__call {
            left: 0px;
            transform: translate(0) scale(1);
            transition-duration: 0.4s;
            transition-property: transform;
          }

          &__line {
            height: 18px;
          }

          &__title {
            display: none;
          }
        }
      }
    }
  } 
}