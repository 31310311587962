@import "../../../assets/styles/colors.scss";

.text-icon {
  display: flex;
  cursor: pointer;
  .icon-container {
    margin-top: 0.075rem;
  }

  .text-label {
    cursor: pointer;
    color: #2c2c30;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    padding-right: 0.688rem;
    padding-left: 0.469rem;
  }
}
