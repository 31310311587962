@import "../../assets/styles/colors.scss";

.card-info {
  &.login {
    max-width: 25.5rem !important;
    padding: 1rem;
    height: 4.5rem;
    margin-top: 1.25rem;
    margin-bottom: 1.5rem;

    .content-wrapper {
      max-width: 18.5rem;
    }
  }
}

.otp-align {
  padding-bottom: 2rem;
  min-height: calc(100vh - 191px);
  display: table;
  margin: 0 auto;

  .otp-inner {
    display: table-cell;
    vertical-align: middle;
  }
}

.fingerprint-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .title {
    color: #2C2C30;
    margin: 0;
    font-size: 20px;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 24px;
  }
  .description {
    color: #2C2C30;
  }
}

@media (max-width: 1200px) {
  .otp-align {
    min-height: calc(100vh - 7.5rem);
    padding-top: 2rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin: 0 auto;
    justify-content: center;
  }

  .card-info {
    &.login {

      height: 5.75rem;

      .content-wrapper {
        max-width: 13.4375rem;
      }

    }
  }
}
.mt-5 {
  padding-top: 5rem;

  .will-be-back-soon .icon-container img {
    margin: -25px;
  }

  .error-page .title-container {
    margin-top: 0rem;
  }

  .error-page .button-container-login {
    max-width: 13rem;
    width: 100%;
    margin-top: 0rem;
    padding-top: 1rem;
  }
}
@media (max-width: 1200px) {
  .mt-5 {
    min-height: calc(100vh - 7.5rem);
    padding-top: 3.5rem;
  }
}
.login-content {
  flex: 1 auto;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  &.fit-footer {
    display: flex;
    justify-content: space-between;
  }
}

.title-leftBlock {
  font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
  font-size: 2rem;
  line-height: 2.375rem;
  color: $blue500;
  padding-top: 3.5rem;
  padding-bottom: 16px;
}

.text-header-login {
  color: #2c2c30;
  font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 16px;
}

.left-block {
  background-color: #f4f6f9;
  min-height: calc(100vh - 9rem);

  .left-content {
    max-width: 324px;
    margin: 0 auto;

    .text-icon {
      display: flex;
      margin-bottom: 1rem;

      .icon-container {
        margin-right: 12px;
      }
    }

    .subtitle {
      color: #2c2c30;
      font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    .text-label-mb {
      color: #2c2c30;
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 8px;
      margin-bottom: 16px;
    }

    .text-label {
      color: #2c2c30;
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1rem;
      line-height: 26px;
    }

    .short-text-label {
      color: $darkGray500;
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1rem;
      line-height: 1.625rem;
      width: 16rem;
      padding-right: 0.469rem;
      padding-left: 0.469rem;
    }
  }
}

@media (max-width: 1200px) {
  .left-block {
    min-height: 100%;
    .left-content {
      margin: 1.5rem;
      display: block;
    }
  }
  .title-leftBlock {
    font-size: 1.75rem;
    line-height: 2rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0.938rem;
  }
}

.right-block {
  margin: 0 auto;
  max-width: 408px;
  width: 100%;
  min-height: calc(100vh - 191px);
  display: table;

  .step-login-container {
    // margin: 0 auto;
    // display: table-cell;
    // vertical-align: middle;
  }

  @media (min-width: 1800px) {
    .step-login-container {
      // margin: unset !important;
    }
  }

  .error-message-login {
    position: relative;
    width: 100%;
    margin-top: 2rem;
  }
}

.error-message-login {
  z-index: 0;
  box-sizing: content-box;
  text-align: left;
  height: unset !important;
  .align-message-bar {
    align-items: flex-start;
    img {
      margin-top: 0 !important;
    }
    .text-error {
      font-size: 1rem;
    }
  }
}

.right-content {
  max-width: 408px;
  width: calc(100% - 48px);
  margin: 0 auto;
  min-height: calc(100vh - 191px);
  display: table;

  .step-login-container {
    margin-top: 5.5rem;
    // margin: 0 auto;
    // display: table-cell;
    // vertical-align: middle;
  }

  .title-client-page {
    color: $blue500;
    font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1.75rem;
    margin: 0 auto;
    padding-top: 3.563rem;
    padding-bottom: 1.875rem;
    text-align: center;
    line-height: 2.125rem;
  }

  .checkbox-container {
    .checkbox-label {
      color: #929296;
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 0.875rem;
      line-height: 1rem;
      margin-top: 0 !important;
      padding-top: 0.1rem !important;

      display: inline-block;
      text-align: left;
      margin-left: 1.5rem;
    }
  }
}

.title-login {
  color: $blue500;
  font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
  font-size: 1.75rem;
  margin: 0 auto;
  padding-top: 7.125rem;
  padding-bottom: 1.875rem;
  text-align: center;
  line-height: 2.125rem;
}

@media (max-width: 1200px) {
  .title-login {
    font-size: 1.375rem;
    line-height: 1.75rem;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
  }
}
.align-input {
  margin: 0 auto;
  text-align: center;
}

.button-container-login {
  max-width: 53rem;
  margin: 0 auto;
  padding-top: 1.5rem;
  padding-bottom: 1.563rem;
  .button-sizes {
    width: 100%;
  }
}

@media (max-width: 1400px) {
  .button-container-login {
    max-width: 25.5rem;
    display: block;

    .button-sizes {
      margin-bottom: 1.5rem;
    }
  }
}

@media (max-width: 1200px) {
  .right-block,
  .right-content {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin: 0 auto;
    justify-content: center;
    min-height: 100%;
    max-width: 20.4375rem;

    .checkbox-label {
      color: var(--darkGrey500);
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 0.875rem;
      line-height: 1rem;
      margin-top: 0.313rem;
      display: inline-block;
      text-align: left;
    }

    .title-client-page {
      padding-top: 0;
      font-size: 1.375rem;
      text-align: center;
      line-height: 1.75rem;
      padding-bottom: 1.5rem;
    }
  }
}
