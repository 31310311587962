$darkGrayishBlue: #f9fafc;
$grayFooter: #f5f5f5;
$grayFooterText: #4a4a4a;
$darkGrayishBlue50: #f9fafc;
$darkGrayishBlue100: #f4f6f9;
$darkGrayishBlue200: #e9ecf3;
$darkGrayishBlue300: #dee3ed;
$darkGrayishBlue400: #d3d9e7;
$circleColor: #f4f6f9;
$darkGray100: #ececed;
$darkGray200: #dbdbdc;
$darkGray300: #b7b7b9;
$darkGray500: #4a4a50;
$blue50: #e7e9ee;
$blue200: #9fa8be;
$blue500: #0f265c;
$info: #2f7abf;
$darkGray: #4a4a4a;
$strongBlue500: #2f7abf;
$grayscale400: #6e6e73;
$grayscale500: #2c2c30;
