@import "../../assets/styles/colors.scss";

.cta {
  margin-top: 5rem;
  position: relative;
  min-height: auto;
  .button--secondary {
    background-color: transparent;
  }
  .banner {
    width: 100%;
    min-height: 580px;
  }

  .icon-button {
    position: absolute;
    bottom: 16px;
    height: 32px;
    width: 32px;
    background-color: var(--blue500);
    left: calc(100vw / 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    cursor: pointer;
    animation-duration: 3s;
  }
  .section-container-mobile {
    display: none;
  }
  @media (max-width: 769px) {
    .banner {
      width: 107%;
      min-height: 250px !important;
    }
    .icon-button {
      display: none;
    }
    .buttons {
      margin-top: 2rem !important;
    }
  }
  .section {
    padding-left: 0;

    &--left {
      position: absolute;
      width: 35%;
      margin: 0;
      text-align: left;
      top: 148px;
      left: 109px;
    }

    @media (max-width: 1024px) {
      &--left {
        top: 70px;
        left: 60px;
      }
    }

    @media (max-width: 767px) {
      &--left {
        position: unset;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .title {
    font-weight: normal;
    margin: 0;
    padding: 0;
    font-size: 2rem;
    font-family: preloslab-semibold, Helvetica, Arial, Sans, Sans-serif;
    animation-duration: 2.3s;
  }

  .content {
    margin: 0;
    padding: 0;
    color: var(--darkGrey500);
    font-size: 1.25rem;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    max-width: 29.563rem;
    animation-duration: 5s;
  }

  .title-desk {
    visibility: visible;
    position: relative;
  }

  .title-mobile {
    visibility: hidden;
    position: absolute;
  }

  .buttons {
    display: flex;
    margin-top: 3rem;
    padding-right: 1rem;
    animation-duration: 3s;

    .desk-button-right {
      visibility: visible;
      position: relative;
    }
    .desk-button-left {
      visibility: visible;
      margin-right: 1rem;
      position: relative;
    }
  }

  @media (max-width: 767px) {
    .section-container-mobile {
      display: block;
    }
    .title-desk {
      visibility: hidden;
      position: absolute;
    }

    .title-mobile {
      visibility: visible;
      position: relative;
    }

    .buttons {
      margin-top: 1.5rem;
      justify-content: center;
      padding: 0 24px;
      display: block;
      .desk-button-right {
        visibility: hidden;
        position: absolute;
      }
      .desk-button-left {
        visibility: hidden;
        position: absolute;
      }
    }

    .content {
      font-size: 1.125rem;
    }
  }
}

@media (max-width: 991px) {
  .cta {
    flex-direction: column-reverse;
    padding-left: 0;
    margin-top: 3.5rem;
    .section {
      padding: 0;
      width: auto;
    }
  }
}

@media (max-width: 1024px) {
  .cta {
    padding-left: 0;
  }
  .banner {
    width: 100%;
    min-height: 300px !important;
  }
}

@media (max-width: 500px) {
  .banner {
    min-height: 250px !important;
    position: inherit;
    width: 160% !important;
    right: calc(100vw - 47vw);
  }
}
