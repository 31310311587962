@import "../../assets/styles/colors.scss";

.footer-Bp {
  background-color: $darkGrayishBlue;
  .body {
    margin: 0 auto;
    padding-top: 68px;
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      justify-content: space-evenly;
      .link-section {
        margin-top: 0.5rem;
      }

      .contact-number {
        cursor: pointer;
        margin-top: 0.5rem;
        display: flex;
        border: solid 1px var(--blue500);
        border-radius: 4px;
        padding: 0.5rem 3.188rem 0.5rem 2.688rem;
        justify-content: center;
        align-items: center;
        pichincha-icon {
          margin-right: 0.313rem;
          display: flex;
          transform: translateY(1px);
        }
        label {
          cursor: pointer;
          font-family: prelo-medium, Helvetica, Arial, Sans, sans-serif;
          font-weight: normal !important;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: inherit;
          color: var(--blue500);
        }
      }
      .contact-number:hover {
        background-color: #e9ecf3;
      }
    }
    .recaptcha-disclaimer {
      margin: 40px 16px 16px;
    }
  }
  @media (max-width: 650px) {
    .body {
      margin: 0px 24px 0 24px;
      padding-top: 0;
      .content {
        display: block;
        .title-section {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
        }
      }
    }
  }
}
