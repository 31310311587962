@import "../../assets/styles/colors.scss";


.dropdown-input {
  position: absolute;
  z-index: 200;
  overflow-y: scroll;
  margin-top: -0.438rem;
  max-height: 8.7rem;
  width: 19.65rem;
  background-color: white;
  border: 0.063rem solid #ced4da;
  border-radius: 0.25rem;
}
.dropdown-input::-webkit-scrollbar {
  width: 0.75rem;
  height: 30rem;

  border-right: 0.063rem solid #ced4da;
  background: white;
}

.dropdown-input::-webkit-scrollbar-thumb {
  background: $darkGray200;
  border-radius: 0.313rem;
  background-clip: padding-box;
  border: 0.25rem solid rgba(0, 0, 0, 0);
  margin-left: -0.75rem;
}
.dropdown-input ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0.375rem 0;
  color: #212529;
  text-align: left;

  margin: 0rem;

  li {
    &:focus {
      background-color: var(--darkGrey200);
      border: none !important;
      outline: none !important;
    }
  }
}

.dropdown-input li {
  font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
  font-size: 0.875rem;
  line-height: 1rem;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  background: transparent;
  cursor: pointer;
}

.wrapper {
  display: inline-block;
  margin: 0.25rem 0;
  max-width: 14.875rem;

  .text-label {
    color: var(--darkGrey500);
    font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    text-align: left;
  }
  i {
    position: absolute;
  }

  .icon {
    color: var(--darkGrey500);
    font-size: 0.875rem;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-style: normal;
    padding-top: 1.3rem;
  }

  .icon-prepend {
    padding-left: 1rem;
    padding-top: 1.28rem;
  }

  .icon-prepend-express {
    padding-left: 1rem;
    padding-top: 1.15rem;
  }
  .icon-append {
    margin-left: -10.5rem;
    padding-top: 1.2rem;
  }

  @media (max-width: 992px) {
    .icon-append {
      margin-left: -16.5rem;
    }
  }

  .phone-wrapper {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    width: calc(100% - 1rem);
    border-radius: 0.25rem;

    .prefix-num {
      width: 2.563rem;
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 0.875rem;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      color: #4a4a50;
      background: #f4f6f9;
      box-sizing: border-box;
      border-top: 0.063rem solid var(--darkGrey200);
      border-bottom: 0.063rem solid var(--darkGrey200);
      border-left: 0.063rem solid var(--darkGrey200);
      border-radius: 0.25rem 0 0 0.25rem;
      justify-content: center;
    }

    .large {
      margin: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .input-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 0.063rem solid #ced4da;
    border-radius: 0.25rem;
  }

  input,
  select,
  textarea {
    width: calc(100% - 2.125rem);

    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;

    background-color: var(--white);
    height: auto;
    box-sizing: unset;
    padding-left: 0.938rem;
    padding-right: 0.938rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 0.063rem solid var(--darkGrey200);
    border-radius: 0.25rem;

    font-size: 0.875rem;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-weight: 100;
    color: var(--darkGrey500);

    &.tooltip {
      margin-top: 0.4rem;
    }

    &.icon-prepend {
      padding-left: 2rem;
      padding-right: 0;
    }

    &::placeholder {
      color: var(--darkGrey400);
    }

    &:hover {
      border-color: var(--darkGrey400);
    }

    &:focus {
      &.icon-prepend {
        padding-left: 2rem;
      }
    }

    &.extra-small {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;

      &:focus {
        padding-top: 0.313rem;
        padding-bottom: 0.313rem;
      }
    }

    &.small {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;

      &:focus {
        padding-top: 0.563rem;
        padding-bottom: 0.563rem;
      }
    }

    &.medium {
      padding-top: 0.875rem;
      padding-bottom: 0.875rem;

      &:focus {
        padding-top: 0.813rem;
        padding-bottom: 0.813rem;
      }
    }

    &.large {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;

      &:focus {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }
    }
  }

  select {
    background: url("../../assets/icons/icon-dropdown-select.svg") no-repeat
      right;
    background-position-x: 26rem;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;

    &.placeholder-text {
      color: var(--darkGrey400) !important;
      option {
        color: var(--darkGrey500) !important;
      }
      option:first-child {
        color: var(--darkGrey400) !important;
      }
    }
  }

  @media (max-width: 992px) {
    input {
      width: calc(100% - 3.063rem);
    }
    select {
      background-position-x: 18rem;
    }
  }

  &.error {
    input, select {
      border-color: var(--danger);
      color: var(--danger);
    }

    select > option {
      border-color: var(--darkGrey400);
      color: var(--darkGrey500);
    }
  }

  &.disabled {
    opacity: 1;
    input {
      cursor: not-allowed;
      background-color: var(--darkGrey100);

      &:hover {
        border-color: var(--darkGrey300);
      }
    }
  }

  &.fullWidth {
    display: block;

    input {
      max-width: none;
    }
  }

  .floatIcon {
    width: 1.25rem;
    height: 1.25rem;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 1.313rem;
    right: 0.688rem;
    left: auto;
    transform: initial;
  }

  &--helper {
    .item {
      display: inline-block;
      vertical-align: top;

      &.left {
        width: calc(100% - 3.125rem);
      }

      &.right {
        width: 3.125rem;
        text-align: end;
      }
    }
  }
}

.wrapper-express {
  display: inline-block;
  margin: 0.25rem 0;
  width: 20rem;

  .text-label {
    color: var(--darkGrey500);
    font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: left;
  }
  i {
    position: absolute;
  }

  .icon {
    color: var(--darkGrey500);
    font-size: 0.875rem;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-style: normal;
    padding-top: 1.35rem;
  }

  .icon-prepend {
    padding-left: 1rem;
    padding-top: 1.28rem;
  }

  .icon-prepend-express {
    padding-left: 1rem;
    padding-top: 1.15rem;
  }

  .icon-append {
    margin-left: -17.5rem;
    padding-top: 1.18rem;
  }

  @media (max-width: 992px) {
    .icon-append {
      margin-left: -17.8rem;
    }
  }

  .phone-wrapper {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    width: calc(100% - 0rem);

    .prefix-num {
      width: 2.563rem;
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 0.875rem;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      color: #4a4a50;
      background: #f4f6f9;
      border: 0.063rem solid var(--darkGrey200);
      box-sizing: border-box;
      border-radius: 0.25rem 0 0 0.25rem;
      justify-content: center;
      margin: -0.063rem;
      margin-top: 0;
      height: 2.719rem;
    }

    .large {
      margin: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .input-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 0.063rem solid #ced4da;
    border-radius: 0.25rem;
  }

  input,
  select {
    width: calc(100% - 2.2rem);

    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;

    background-color: var(--white);
    height: auto;
    box-sizing: unset;
    line-height: unset;
    padding-left: 0.938rem;
    padding-right: 0.938rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 0.063rem solid var(--darkGrey200);
    border-radius: 0.25rem;

    font-size: 0.875rem;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-weight: 100;
    color: var(--darkGrey500);

    &.tooltip {
      margin-top: 0.4rem;
    }

    &.icon-prepend {
      padding-left: 2rem;
      padding-right: 0;
    }

    &::placeholder {
      color: var(--darkGrey400);
    }

    &:hover {
      border-color: var(--darkGrey400);
    }

    &:focus {
      &.icon-append {
        margin-left: 20rem;
      }
      &.icon-prepend {
        padding-left: 2rem;
      }
    }

    &.extra-small {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;

      &:focus {
        padding-top: 0.313rem;
        padding-bottom: 0.313rem;
      }
    }

    &.small {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;

      &:focus {
        padding-top: 0.563rem;
        padding-bottom: 0.563rem;
      }
    }

    &.medium {
      padding-top: 0.875rem;
      padding-bottom: 0.875rem;

      &:focus {
        padding-top: 0.813rem;
        padding-bottom: 0.813rem;
      }
    }

    &.large {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;

      &:focus {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }
    }
  }

  select {
    background: url("../../assets/icons/icon-dropdown-select.svg") no-repeat
      right;
    background-position-x: 24.2rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  &.error {
    input {
      border-color: var(--danger);
      color: var(--danger);
    }
  }

  &.disabled {
    opacity: 1;
    input {
      cursor: not-allowed;
      background-color: var(--darkGrey100);

      &:hover {
        border-color: var(--darkGrey300);
      }
    }
  }

  &.fullWidth {
    display: block;

    input {
      max-width: none;
    }
  }

  .floatIcon {
    width: 1.25rem;
    height: 1.25rem;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 1.313rem;
    right: 0.688rem;
    left: auto;
    transform: initial;
  }

  &--helper {
    .item {
      display: inline-block;
      vertical-align: top;

      &.left {
        width: calc(100% - 3.125rem);
      }

      &.right {
        width: 3.125rem;
        text-align: end;
      }
    }
  }
}

.wrapper-login {
  display: block;
  margin: 0.25rem auto;
  max-width: 100%;
  text-align: left;

  .dropdown-input {
    position: absolute;
    z-index: 200;
    overflow-y: scroll;
    margin-top: -0.438rem;
    max-height: 8.7rem;
    width: 19.65rem;
    background-color: white;
    border: 0.063rem solid #ced4da;
    border-radius: 0.25rem;
  }
  .dropdown-input::-webkit-scrollbar {
    width: 0.75rem;
    height: 30rem;

    border-right: 0.063rem solid #ced4da;
    background: white;
  }

  .dropdown-input::-webkit-scrollbar-thumb {
    background: $darkGray200;
    border-radius: 0.313rem;
    background-clip: padding-box;
    border: 0.25rem solid rgba(0, 0, 0, 0);
    margin-left: -0.75rem;
  }
  .dropdown-input ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0.375rem 0;
    color: #212529;
    text-align: left;

    margin: 0rem;

    li {
      &:focus {
        background-color: var(--darkGrey200);
        border: none !important;
        outline: none !important;
      }
    }
  }

  .dropdown-input li {
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    background: transparent;
    cursor: pointer;
  }

  .text-label {
    color: var(--darkGrey500);
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: left;
    font-weight: bold;
  }
  i {
    position: absolute;
  }

  .icon {
    color: var(--darkGrey500);
    font-size: 0.875rem;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-style: normal;
    padding-top: 1.35rem;
  }

  .icon-prepend {
    padding-left: 1rem;
    padding-top: 1.28rem;
  }

  .icon-prepend-express {
    padding-left: 1rem;
    padding-top: 1.15rem;
  }

  .icon-append {
    margin-left: -17.5rem;
    padding-top: 1.18rem;

    &.icon-tooltip {
      margin-left: -2.5rem !important;
      position: initial;
      margin-top: 0.35rem !important;
      padding-top: 0 !important;
      display: inline-table;
    }

    &.loader {
      position: relative!important;
      img {
        position: absolute;
        margin-top: -1rem;
      }
    }

    .action-link {
      margin-left: -2.5rem;
    }
  }

  @media (max-width: 992px) {
    .icon-append {
      margin-left: -17.8rem;
    }
  }

  .phone-wrapper {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    width: calc(100% - 0rem);

    .prefix-num {
      width: 2.563rem;
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 0.875rem;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      color: #4a4a50;
      background: #f4f6f9;
      border: 0.063rem solid var(--darkGrey200);
      box-sizing: border-box;
      border-radius: 0.25rem 0 0 0.25rem;
      justify-content: center;
      margin: -0.063rem;
      margin-top: 0;
      height: 2.719rem;
    }

    .large {
      margin: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .input-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 0.063rem solid #ced4da;
    border-radius: 0.25rem;
  }

  input,
  select {
    width: calc(100% - 2rem);

    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;

    background-color: var(--white);
    height: auto;
    box-sizing: unset;
    padding-left: 0.938rem;
    padding-right: 0.938rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 0.063rem solid var(--darkGrey200);
    border-radius: 0.25rem;

    font-size: 0.875rem;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    line-height: 1.5rem;
    font-weight: 100;
    color: var(--darkGrey500);

    &.tooltip {
      margin-top: 0.4rem;
    }

    &.icon-prepend {
      padding-left: 2rem;
      padding-right: 0;
    }

    &::placeholder {
      color: var(--darkGrey400);
    }

    &:hover {
      border-color: var(--darkGrey400);
    }

    &:focus {
      &.icon-append {
        margin-left: 20rem;
      }
      &.icon-prepend {
        padding-left: 2rem;
      }
    }

    @media (max-width: 992px) {
      input {
        width: calc(100% - 3.063rem);
      }
    }

    &.extra-small {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;

      &:focus {
        padding-top: 0.313rem;
        padding-bottom: 0.313rem;
      }
    }

    &.small {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;

      &:focus {
        padding-top: 0.563rem;
        padding-bottom: 0.563rem;
      }
    }

    &.medium {
      padding-top: 0.875rem;
      padding-bottom: 0.875rem;

      &:focus {
        padding-top: 0.813rem;
        padding-bottom: 0.813rem;
      }
    }

    &.large {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;

      &:focus {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }
    }
  }

  select {
    background: url("../../assets/icons/icon-dropdown-select.svg") no-repeat
      right;
    background-position-x: 24.2rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  &.error {
    input {
      border-color: var(--danger);
      color: var(--danger);
    }
  }

  &.disabled {
    opacity: 1;
    input {
      cursor: not-allowed;
      background-color: var(--darkGrey100);

      &:hover {
        border-color: var(--darkGrey300);
      }
    }
  }

  &.fullWidth {
    display: block;

    input {
      max-width: none;
    }
  }

  .floatIcon {
    width: 1.25rem;
    height: 1.25rem;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 1.313rem;
    right: 0.688rem;
    left: auto;
    transform: initial;
  }

  &--helper {
    .item {
      display: inline-block;
      vertical-align: top;

      &.left {
        width: calc(100% - 3.125rem);
      }

      &.right {
        width: 3.125rem;
        text-align: end;
      }
    }
  }
  .emailTab {
    input {
      max-width: none;
      background-color: aqua;
    }
  }
}

@media (max-width: 992px) {
  .wrapper {
    max-width: 100%;
    display: block;
  }
  .wrapper-express {
    max-width: 100%;
    display: block;
    width: auto;

    .dropdown-input {
      width: 20.25rem;
    }
  }
  .wrapper-login {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    text-align: left;
  }
}
