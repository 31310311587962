@import "../../../assets/styles/colors.scss";

.message-bar-container {
  &.offer-page {
    position: absolute;
    margin-top: 5.5rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 39rem;
    padding-top: 0;
    .icon-and-message {
      align-items: flex-start;
    }
    img {
      margin-top: 0.3rem;
    }
  }
}

.icon {
  &.prequalification {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $circleColor;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    padding-top: 0.125rem;
    padding-left: 0.125rem;

    &.bg-white {
      background-color: white;
    }
  }
}

.card-info {
  position: relative;
  background-color: $darkGrayishBlue100;
  max-width: 39rem;
  margin: 0 auto;
  display: flex;
  padding: 0 2rem 1.5rem 2rem;
  border-radius: 0.25rem;
  .content-wrapper {
    position: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $grayscale500;
    .subtitle {
      margin-bottom: 0.5rem;
    }
  }
  .icon {
    margin-right: 1.5rem;
    margin-top: 1.2rem;
  }
}
.justify-flex-center {
  justify-content: center !important;
}
.bottom {
  margin-top: 10.625rem;
}

.action-buttons {
  display: flex;
  max-width: 39rem;
  margin: 0 auto;
  justify-content: flex-end;
  margin-bottom: 1.5rem;

  div {
    width: 8.6rem;
  }

  .back {
    margin-right: 1.5rem;
  }

  .back-xl {
    width: 12rem !important;
    margin-right: 1.5rem;
  }

  .continue-xl {
    width: 12rem !important;
  }
}

.main_header {
  margin-top: 5rem;
  height: 14.25rem;
  padding-top: 3.5rem;
  background-color: $darkGrayishBlue100;
}

.offer-container {
  .header_x {
    .info-cards {
      border-radius: 0.25rem;
      max-width: 52.5rem;
      margin: 0 auto;
      height: 5rem;
      background-color: var(--white);
      display: flex;
      align-items: center;
      border: 0.0625rem solid $darkGrayishBlue400;
      justify-content: space-around;

      .card {
        width: 17.5rem;
        display: flex;
        justify-content: center;
      }
    }
  }

  .section-legal {
    max-width: 39rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .summary-wrapper {
    position: relative;
    border: 0.0625rem solid $darkGrayishBlue400;
    border-radius: 0.5rem;
    max-width: 52.5rem;
    margin: 0 auto;
    margin-top: -14rem;
    background: var(--white);
    .header_content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .offer-validation-time {
        pichincha-typography {
          background-color: darkcyan;
          padding: 0 9px 3.25px 9px;
          border-radius: 16px;
        }
      }

      .icon {
        margin-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $circleColor;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        padding-top: 0.125rem;
        padding-left: 0.125rem;
      }
    }
    .row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      .link {
        .text {
          color: var(--info);
          text-decoration: none;
        }
      }
    }

    .info-section {
      color: $grayscale500;
      padding: 2.25rem 2rem 2rem 2rem;
    }

    .collapsible-section {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 0;
      overflow: hidden;
      transition: all 0.2s ease-out;
      .collapsible-content {
        width: 73%;
      }

      &.open {
        max-height: 36rem;
      }

      .subtitle {
        margin-bottom: 1rem;
      }
    }
    .toggle-details {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.5rem;
      background-color: var(--white);
      cursor: pointer;
      .arrow {
        margin-top: 0.35rem;
      }
    }

    .msg-container {
      min-width: 624px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.rejected-container {
  margin-top: 9.5rem;

  .info-icon {
    text-align: center;
  }

  .section-legal {
    color: $grayscale500;
    text-align: center;
  }

  .header-container {
    max-width: 38.938rem;
    margin: 0 auto;
    margin-bottom: 2.5rem;

    &__separator {
      margin-top: 16px;
    }
    .titleCheck {
      padding: 0;
      margin-top: 2.5rem;
    }

    &__link {
      color: var(--info);
      &:hover {
        cursor: pointer;
      }
    }
  }

  .horizontal-separator {
    background-color: $darkGrayishBlue300;
    height: 1px;
    max-width: 66rem;
    margin: 0 auto;
  }

  .last-separator {
    margin-bottom: 2.5rem;
  }
}

.divider {
  align-items: center;
  height: 0.0625rem;
  background-color: var(--darkGrayishBlue300);
}

.w-divider-73 {
  width: 73% !important;
}
.w-divider-90 {
  width: 90% !important;
}

.w-divider-100 {
  width: 100% !important;
}

.bg-white {
  background: var(--white);
}

@media only screen and (max-width: 991px) {
  .main_header {
    margin-top: 1rem;
    height: 14.25rem;
    padding-top: 5.5rem;
    background-color: $darkGrayishBlue100;
  }
  .message-bar-container {
    &.offer-page {
      margin-top: 5rem !important;
      width: 20.4375rem;

      .text-error {
        font-size: 0.875rem;
      }

      .container-message {
        margin-top: 0 !important;
      }
    }
  }

  .card-info {
    max-width: 100%;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    .icon {
      margin-right: 0.75rem;
      margin-bottom: 3.75rem;
    }

    .content-wrapper {
      max-width: 100%;
    }
  }

  .action-buttons {
    max-width: 20.4375rem;
    margin-bottom: 2rem;
    flex-direction: column-reverse;

    div {
      width: 100%;
    }

    .back {
      margin-right: 0;
    }

    .continue {
      margin-bottom: 1.5rem;
    }

    .back-xl {
      width: 100% !important;
      margin-right: 0;
    }

    .continue-xl {
      margin-bottom: 1.5rem;
      width: 100% !important;
    }
  }

  .recaptcha {
    max-width: 20.4375rem;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  .offer-container {
    .header {
      padding-top: 1.5rem;
      height: 19.9375rem;
      margin-top: 3.5rem;

      .offer-title {
        max-width: 21rem;
      }

      .main-amount {
        margin-bottom: 5rem;
      }

      .info-cards {
        flex-direction: column;
        border: none;
        height: 17rem;
        max-width: 100%;
        margin: 0;

        .vertical-separator {
          display: none;
        }

        .card {
          margin-top: -5rem;
          border: 0.0625rem solid $darkGrayishBlue400 !important;
          height: 5rem;
          border-radius: 0.25rem;
          width: 100%;
          max-width: 17.0625rem;
          margin-bottom: 1rem;
          background-color: var(--white);
          justify-content: flex-start;
          padding-left: 3.25rem;
          align-items: center;

          .info {
            .title {
              padding: 0;
            }
          }
        }
      }
    }

    .section-legal {
      max-width: 20.375rem;
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    .summary-wrapper {
      max-width: 100%;
      margin: -14rem 1rem 0 1rem !important;
      position: relative;
      .second-section {
        padding: 1.5rem;
      }

      .collapsible-section {
        .row {
          .left-text {
            max-width: 14rem;
          }
        }

        .collapsible-content {
          padding: 2.25rem 1.5rem 1.5rem 1.5rem;
        }
        &.open {
          max-height: 55rem;
        }
      }
    }
  }

  .mobile-container {
    max-width: 20.4375rem;
    margin: 0 auto;
  }

  .rejected-container {
    margin-top: 5rem;

    .info-icon {
      text-align: center;
    }

    .header-container {
      margin-bottom: 2rem;
    }

    .last-separator {
      margin-bottom: 3rem;
    }
  }
}
