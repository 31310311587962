.radiobutton-container {
  position: relative;

  &--slot {
    display: inline-block;
    margin-left: 1.75rem;
    margin-top: 0.125rem;
  }

  input[type="radio"] {
    width: 0.938rem;
    height: 0.938rem;
    color: black;
    border: 0.125rem solid #929292;
    border-radius: 3.125rem;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    position: absolute;
    top: 0.25rem;

    &:hover {
      border: 0.125rem solid #4a4a4a;

    }

    &:focus {
      border: 0.125rem solid #2f7abf !important;
    }

    &:checked {
      color: white;
      border-color: #0f265c;
      background: #0f265c;

      &::before {
        position: absolute;
        content: "";
        opacity: 1;
        display: block;
        left: 0.125rem;
        top: 0.125rem;
        width: 0.438rem;
        height: 0.438rem;
        border-style: solid;
        background: white;
        border-width: 0;
        border-radius: 50%;
      }
    }

    &:disabled {
      color: white;
      border-color: #c8c8c8 !important;
      cursor: not-allowed;
      &::before {
        position: absolute;
        content: "";
        opacity: 1;
        display: block;
        left: 0.125rem;
        top: 0.125rem;
        width: 0.438rem;
        height: 0.438rem;
        border-style: solid;
        background: white;
        border-width: 0;
        border-radius: 50%;
      }
    }
    &:checked:disabled{
      background: #c8c8c8 !important;
    }
  }

}

@media (max-width: 992px) {
  .radiobutton-container {  
    &--slot {
      margin-top: 0.172rem;
    }
  }
}