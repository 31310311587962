.float {
  position: fixed;
  width: 3rem;
  height: 3rem;
  bottom: 1rem;
  right: 1rem;
  background-color: var(--white);
  color: #fff;
  border-radius: 3.125rem;
  text-align: center;
  box-shadow: 0 0 0.25rem rgba(7, 7, 7, 0.16), 0 0.75rem 0.75rem -0.5rem rgba(7, 7, 7, 0.16);
  display: flex;
  justify-content: center;

  img {
    width: 1rem;
  }

}