@import "../src/assets/styles/colors.scss";
@import "animate.css";

.container-section {
  max-width: 90rem;
  margin: 0 auto;
}
.agency-express-request-success-container {
  margin-top: 2.5rem;
}

.error-page-margin {
  margin-top: 13rem;
  display: flex;
  justify-content: center;
  &.wentwrong-page {
    margin-top: 9rem;
  }
  &.with-no-margin {
    margin-top: 0;
  }
}

.text {
  &--main {
    color: var(--blue500);
    font-size: 2rem;
  }
}

.title,
.content {
  color: var(--blue500);
}

a {
  text-decoration: none;
}

.mr-1 {
  margin-right: 1rem;
}

@media (max-width: 61.938rem) {
  .title {
    padding: 0 1rem 1rem 1rem;
  }
  .agency-express-request-success-container {
    margin-top: 0;
  }
}

@media (max-width: 61.938rem) {
  .cta {
    flex-direction: column-reverse;
  }
}

.benefits-box {
  display: flex;
  text-align: center;
  flex-flow: column;
  justify-content: center;
  padding: 1rem 5% 2rem 5%;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
  background-color: var(--darkGrayishBlue50);
  .h2-title {
    margin-top: 3.5rem;
    margin-bottom: 3rem;
    color: $darkGray500;
    font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1.75rem;
    line-height: 2.125rem;
    font-weight: normal;
  }
  .box-content {
    display: flex;
    text-align: center;
    flex-flow: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}

@media (max-width: 61.938rem) {
  .benefits-box {
    display: flex;
    text-align: center;
    flex-flow: column;
    justify-content: center;
    padding-top: 0;

    .h2-title {
      margin-top: 2rem;
      margin-bottom: 3rem;
      color: var(--blue500);
      font-family: preloslab-semibold, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1.75rem;
      line-height: 2.125rem;
      padding: 0 1rem;
    }

    .box-content {
      display: flex;
      text-align: center;
      flex-flow: row;
      align-items: center;
      padding: none;
      margin-top: 0.5rem;
      flex-wrap: wrap;
    }
  }
}

p {
  margin-block-start: 0.75rem;
  margin-block-end: 0.75rem;
}

hr {
  border: 1px solid var(--darkGrayishBlue300);
}

.boxOrder {
  display: flex;
  text-align: center;
  flex-flow: column;
  justify-content: flex-start;
  margin: 0.75rem 0 0 0;

  .title-benefit {
    color: var(--blue500);
    font-size: 1.125rem;
    font-family: preloslab-bold, Helvetica, Arial, Sans, Sans-serif;
    padding-top: 1rem;
    line-height: 1.318rem;
    font-weight: 700;
  }

  .subtitle-benefit {
    color: var(--darkGrey500);
    font-size: 1rem;
    font-family: preloslab, Helvetica, Arial, Sans, Sans-serif;
    width: 18.75rem;
    line-height: 1.5rem;
    font-weight: 500;
  }

  .box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .circle-icon {
      z-index: -2;
      position: absolute;
      background: $circleColor;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      text-align: center;
      line-height: 6.25rem;
      justify-content: center;
      vertical-align: middle;
    }
    .icon-benefit {
      margin-top: 0.25rem;
    }
  }
}

.boxOrder-express {
  display: flex;
  text-align: center;
  flex-flow: column;
  justify-content: flex-start;
  margin: 0.75rem 0 0 0;

  .title-benefit {
    padding-top: 1rem;
    line-height: 1.318rem;
  }

  .subtitle-benefit {
    width: 14.5rem;
    height: 0rem;
    margin: 0;
    line-height: 1.5rem;
  }

  .box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .circle-icon {
      z-index: -2;
      position: absolute;
      background: $circleColor;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      text-align: center;
      line-height: 6.25rem;
      justify-content: center;
      vertical-align: middle;
    }
    .icon-benefit {
      margin-top: 0.25rem;
    }
  }
}
@media (max-width: 61.938rem) {
  .boxOrder {
    display: flex;
    text-align: center;
    flex-flow: column;
    justify-content: flex-start;
    margin: 0 0 2.5rem 0;
  }

  .boxOrder-express {
    display: flex;
    text-align: center;
    flex-flow: column;
    justify-content: flex-start;
    margin: 0 0 2.5rem 0;
  }
}

.steps-content {
  display: flex;
  text-align: center;
  flex-flow: column;
  justify-content: flex-start;
  padding: 3.5rem 0 1.75rem 0;
  max-width: 64rem;
  margin: 0 auto;

  .title-section {
    margin: 3.5rem 0 2.5rem 0;
    color: var(--darkGrey500);
    font-family: preloslab-semibold, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1.75rem;
    line-height: 2.125rem;
    font-weight: normal;
  }
  .cardsection-right {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .image-position {
      text-align: initial;
      z-index: -2;
      height: auto;
    }
    .cardtext {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .cardsection-left {
    padding: 1rem 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    .image-position {
      text-align: initial;
      z-index: -2;
      height: auto;
    }
    .cardtext {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

@media (max-width: 61.938rem) {
  .steps-content {
    display: flex;
    text-align: center;
    flex-flow: column;
    justify-content: flex-start;
    padding: 0 0;
    margin: 0 0;
    .title-section {
      padding: 2rem 1rem;
      margin: 0 0;
    }

    .cardsection-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      padding: 1rem 0 2rem 0;
      .image-position {
        text-align: initial;
        z-index: -2;
        height: auto;
        width: 100%;
      }
      .cardtext {
        display: flex;
        justify-content: flex-end;
        position: relative;
        margin-top: -3.125rem;
        padding: 0rem;
        width: 100%;
      }
    }
    .cardsection-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem 0 2rem 0;

      .image-position {
        text-align: initial;
        z-index: -2;
        height: auto;
        width: 100%;
        padding: 0;
      }
      .cardtext {
        display: flex;
        justify-content: flex-end;
        position: relative;
        margin-top: -3.125rem;
        padding: 0rem;
        width: 100%;
      }
    }
  }
}

.grecaptcha-badge {
  display: none;
}

.footer {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  background: $grayFooter;
  z-index: 9;

  .footer-content {
    max-width: 86rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    height: 4rem;
    padding: 0 2rem;
    .text {
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: $grayFooterText;
      position: relative;
      visibility: visible;
    }
    .text-mobile {
      position: absolute;
      visibility: hidden;
    }
  }

  @media (max-width: 61.938rem) {
    .footer-content {
      padding: 0 1rem;
      justify-content: center;
      .text {
        text-align: center;
        position: absolute;
        visibility: hidden;
      }
      .text-mobile {
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        font-size: 0.875rem;
        line-height: 1.125rem;
        text-align: center;
        color: $grayFooterText;
        position: relative;
        visibility: visible;
      }
    }
  }
}

.container-amortizations {
  max-width: 53rem;
  margin: 0 auto;
  padding-top: 2rem;
}

@media (max-width: 61.938rem) {
  .container-amortizations {
    max-width: 20.5rem;
  }
}

.container-products {
  max-width: 53rem;
  margin: 0 auto;
  padding-bottom: 2rem;
  margin-top: 5rem;
}
@media (max-width: 61.938rem) {
  .container-products {
    max-width: 20.5rem;
  }
}

.titleCheck {
  font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
  font-size: 2rem;
  line-height: 2.375rem;
  color: $blue500;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (max-width: 61.938rem) {
  .titleCheck {
    font-size: 1.75rem;
    line-height: 2.125rem;
    color: $blue500;
    text-align: center;
    padding-top: 0;
    padding-bottom: 2.125rem;
  }
}
.amortization-title {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;
}

.titleQuestion {
  font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
  font-size: 1rem;
  line-height: 1.625rem;
  color: $darkGray500;
  text-align: left;
  margin-right: 0.4rem;
}
.desktop-style {
  position: relative !important;
  visibility: visible !important;
}
.mobile-style {
  position: absolute !important;
  visibility: hidden !important;
}

.link-product {
  cursor: pointer;
  color: var(--blue300);
  font-size: 0.875rem;
  line-height: 1.438rem;
  text-align: end;
}
@media (max-width: 61.938rem) {
  .desktop-style {
    position: absolute !important;
    visibility: hidden !important;
  }
  .mobile-style {
    position: relative !important;
    visibility: visible !important;
  }
}

.click-out {
  position: fixed;
  background-color: transparent;
  width: 100%;
  height: 100%;
  z-index: 900;
}

.desktop-form {
  position: relative;
  visibility: visible;
  display: block;
  margin-top: 5rem;

  .contain-size {
    max-width: 90rem;
    margin: 0 auto;
    height: 55rem;
    .box-contain {
      background: white;
      box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(7, 7, 7, 0.16);
      width: 49.5rem;
      height: 44.25rem;
      margin-top: -49.625rem;
      display: grid;
      align-items: center;
      justify-content: start;
      float: right;
      position: relative;
    }
  }
}
.mobile-formContent {
  visibility: hidden;
  display: none;
  position: absolute;
  margin-top: 3.5rem;
}
@media only screen and (max-width: 61.938rem) {
  .desktop-form {
    display: none;
    visibility: hidden;
    position: absolute;
  }
  .mobile-formContent {
    position: relative;
    visibility: visible;
    display: block;
  }
}

.calculator-section-test {
  scroll-margin-top: 5rem;
}

.form-schedule {
  padding-left: 3.5rem;
  width: 29.5rem;
  .size-text {
    width: 27.5rem;
    .title-style {
      font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
      font-size: 2rem;
      line-height: 2.375rem;
      color: $blue500;
    }
    .subtitle-style {
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1rem;
      line-height: 1.625rem;
      color: $darkGray500;
    }
  }
  .title-schedule {
    font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $blue500;
  }
  .button-schedule {
    background: white;
    border: 0.063rem solid $darkGray300;
    box-sizing: border-box;
    border-radius: 0.25rem;
    width: 29.5rem;
    height: 3rem;
    cursor: pointer;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $darkGray500;
  }
  .button-schedule-selected {
    background: $circleColor;
    border: 0.125rem solid $info;
    box-sizing: border-box;
    border-radius: 0.5rem;
    width: 29.5rem;
    height: 3rem;
    cursor: pointer;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $darkGray500;
  }
  .last-buttons {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 29.5rem;
    .button-size {
      min-width: 13.813rem;
      margin: 0;
      cursor: none;

      &.bg-secondary {
        background-color: $darkGrayishBlue200;
      }
    }
  }
}

.form-schedule-mobile {
  max-width: 20.5rem;
  margin: 0 auto;
  padding: 2.5rem 0;
  .size-text {
    width: 20.5rem;
    .title-style {
      font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
      font-size: 2rem;
      line-height: 2.375rem;
      color: $blue500;
    }
    .subtitle-style {
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1rem;
      line-height: 1.625rem;
      color: $darkGray500;
      text-align: center;
    }
  }
  .title-schedule {
    font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $blue500;
  }
  .button-schedule {
    background: white;
    border: 0.063rem solid $darkGray300;
    box-sizing: border-box;
    border-radius: 0.25rem;
    width: 20.5rem;
    height: 3rem;
    cursor: pointer;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $darkGray500;
  }
  .button-schedule-selected {
    background: $circleColor;
    border: 0.125rem solid $info;
    box-sizing: border-box;
    border-radius: 0.5rem;
    width: 20.5rem;
    height: 3rem;
    cursor: pointer;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $darkGray500;
  }
  .last-buttons {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 20.5rem;
    height: 7rem;
    .button-size {
      min-width: 20.5rem;
      margin: 0;
      &.bg-secondary {
        background-color: $darkGrayishBlue200;
      }
    }
  }
}

.form-loading {
  padding-left: 3.5rem;
  width: 29.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .text-load {
    font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $blue500;
    text-align: center;
  }
}
@media only screen and (max-width: 61.938rem) {
  .form-loading {
    width: auto;
    padding: 0;
    height: 32.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.final-form {
  padding-left: 3.5rem;
  width: 29.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .title-name {
    font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
    font-size: 2rem;
    line-height: 2.375rem;
    color: $blue500;
    text-align: center;
  }
  .statements {
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $darkGray500;
    text-align: center;
  }
  .box-info {
    width: 18.5rem;
    height: 2.5rem;
    background: $darkGrayishBlue50;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .data-style {
      font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
      font-size: 0.875rem;
      line-height: 1.438rem;
      color: $blue500;
      text-align: center;
    }
  }
  .last-button {
    min-width: 18.5rem;
    margin: 0;
  }
}

@media only screen and (max-width: 61.938rem) {
  .final-form {
    padding: 2.5rem 0;
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .title-name {
      font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
      font-size: 2rem;
      line-height: 2.375rem;
      color: $blue500;
      text-align: center;
    }
    .statements {
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1rem;
      line-height: 1.625rem;
      color: $darkGray500;
      text-align: center;
    }
    .box-info {
      width: 18.5rem;
      height: 2.5rem;
      background: $darkGrayishBlue50;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .data-style {
        font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
        font-size: 0.875rem;
        line-height: 1.438rem;
        color: $blue500;
        text-align: center;
      }
    }
    .last-button {
      min-width: 18.5rem;
      margin: 0;
      margin-top: -0.5rem;
    }
  }
}

.form-input {
  padding-left: 3.5rem;
  width: 29.5rem;
  .size-text {
    width: 27.5rem;
    .title-style {
      font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
      font-size: 2rem;
      line-height: 2.375rem;
      color: $blue500;
    }
    .subtitle-style {
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1rem;
      line-height: 1.625rem;
      color: $darkGray500;
    }
  }
  .title-inputForm {
    font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
    font-size: 1rem;
    line-height: 1.625rem;
    color: $blue500;
  }
  .title-input {
    font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
    color: $darkGray500;
  }
  .display-none {
    display: none;
  }
  .last-text {
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    font-size: 0.75rem;
    line-height: 1.188rem;
    color: #4a4a4a;
    text-align: left;
  }
  .last-buttons {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 29.5rem;
    .button-size {
      min-width: 13.813rem;
      margin: 0;
    }
  }
  &.thank-you-page {
    max-width: 39rem;

    .image-wrapper {
      display: flex;
      justify-content: center;

      img {
        width: 20.5rem;
      }
    }

    .titleCheck {
      padding-top: 1.5rem;
    }

    .final-text {
      margin-bottom: 2rem;
    }

    .button-container {
      max-width: 13rem;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 61.938rem) {
  .form-input {
    max-width: 20.5rem;
    margin: 0 auto;
    padding: 2.5rem 0;
    .size-text {
      width: 20.5rem;
      .title-style {
        font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
        font-size: 2rem;
        line-height: 2.375rem;
        color: $blue500;
      }
      .subtitle-style {
        font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
        font-size: 1rem;
        line-height: 1.625rem;
        color: $darkGray500;
      }
    }
    .title-inputForm {
      font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
      font-size: 1rem;
      line-height: 1.625rem;
      color: $blue500;
    }
    .title-input {
      font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
      font-size: 0.875rem;
      line-height: 1rem;
      color: $darkGray500;
    }
    .display-none {
      display: none;
    }
    .last-text {
      font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
      font-size: 0.75rem;
      line-height: 1.188rem;
      color: #4a4a4a;
      text-align: center;
    }
    .last-buttons {
      padding-top: 1.5rem;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      width: 20.5rem;
      height: 7rem;
      .button-size {
        min-width: 20.5rem;
        margin: 0;
      }
    }
    &.express-request {
      margin-top: 2.25rem !important;
    }
    &.thank-you-page {
      .final-text {
        margin-bottom: 1.5rem;
      }
    }
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.error-page {
  max-width: 39rem;
  margin: 4.625rem auto 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .agency-success-page-title {
    font-size: 1.75rem;
    text-align: center;
  }

  &.blocked-page {
    margin-top: 7rem;
  }
  .title-container {
    margin-top: 2.5rem;
  }

  .title {
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin-top: 1rem;
    margin-bottom: 0;
    padding: 0;
    font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif;
    color: $blue500;
  }

  .text-container {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
  }

  .button-container {
    max-width: 13rem;
    width: 100%;
    margin-top: 2rem;
  }
}

.loading-page {
  margin-top: 9.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 14rem);

  .titleCheck {
    padding-bottom: 1rem;

    &.loading-page-title {
      max-width: 39rem;
      margin: 0 auto;
    }
  }

  .subtitle {
    color: $grayscale500;
    margin-bottom: 2rem;
  }

  .tip-title {
    color: $grayscale400;
    margin-top: 2rem;
  }

  .tip {
    color: $grayscale400;
    max-width: 408px;
    margin: 0 auto;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 61.938rem) {
  .error-page {
    .button-container {
      max-width: 20.5rem;
    }

    .padding-wrapper {
      padding: 0 1rem;
    }

    .agency-success-page-title {
      font-size: 1.375rem;
    }
  }
}

.calification-container {
  padding-top: 3.625rem;
  max-width: 30.938rem;
  margin: 0 auto;
  text-align: center;
  .title {
    padding-top: 2.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.75rem;
    line-height: 2.125rem;
    font-family: preloslab-semibold, Helvetica, Arial, Sans, Sans-serif;
    color: $darkGray500;
  }
  .subtitle {
    padding-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.625rem;
    font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
    color: #4a4a50;
  }
  .button {
    width: 12.938rem;
    margin: 0 auto;
  }
}

.text-dark-grey-500 {
  color: var(--darkGrey500) !important;
}

.text-blue-500 {
  color: var(--blue500) !important;
}

.text-prelo-medium {
  font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
}

.text-prelo-bold {
  font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
}

.text-prelo-slab-semibold {
  font-family: preloslab-semibold, Helvetica, Arial, Sans, Sans-serif !important;
}

.text-prelo-semibold {
  font-family: prelo-semibold, Helvetica, Arial, Sans, Sans-serif !important;
}

.text-prelo {
  font-family: prelo, Helvetica, Arial, Sans, Sans-serif;
}

.text-prelo-book {
  font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center;
}

.text-font-size-14 {
  font-size: 0.875rem;
}

.text-font-size-12 {
  font-size: 0.75rem;
}

.text-font-size-28 {
  font-size: 1.75rem;
}

.text-line-height-26 {
  line-height: 1.625rem;
}

.text-line-height-19 {
  line-height: 1.1875rem;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-22 {
  margin-left: 22rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-176 {
  margin-top: 11rem;
}

@media (max-width: 991px) {
  .md-mb-0 {
    margin-bottom: 0 !important;
  }
  .md-pt-48 {
    padding-top: 3rem !important;
  }
  .lg-mt-16 {
    margin-top: 1rem;
  }
}

@media (min-width: 992px) {
  .lg-ml-40 {
    margin-left: 2.5rem !important;
  }
  .lg-mt-24 {
    margin-top: 1.5rem !important;
  }
}

.pichincha-tabs__inline-tab {
  display: flex !important;
}
.pichincha-tabs__header {
  border-bottom: none !important;
}
.circle-tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: static;
  width: calc(100% - 0rem);
  height: 36px;
  border: 1px solid #bec7db;
  box-sizing: border-box;
  border-radius: 0px 8px 8px 0px;
  &.right {
    border-radius: 0px 8px 8px 0px !important;
    border-left: none;
    .tab {
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 1px 0 !important;
      margin: 0px 0px !important;
      font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
      font-size: 12px !important;
      line-height: 20px !important;
      text-align: center !important;
      color: #929296 !important;
      border: none !important;
      height: 32px;
      width: calc(100% - 0rem);
      &.active {
        color: #0f265c !important;
        background: #e9ecf3;
        border: 1px solid #c7cfe1;
        border-radius: 0px 8px 8px 0px;
      }
    }
  }
  &.left {
    border-radius: 8px 0px 0px 8px;
    .tab {
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 1px 0 !important;
      margin: 0px 0px !important;
      font-family: preloslab-book, Helvetica, Arial, Sans, Sans-serif;
      font-size: 12px !important;
      line-height: 20px !important;
      text-align: center !important;
      color: #929296 !important;
      border: none !important;
      height: 32px;
      width: calc(100% - 0rem);
      &.active {
        color: #0f265c !important;
        background: #e9ecf3;
        border: 1px solid #c7cfe1;
        border-radius: 8px 0px 0px 8px;
      }
    }
  }
}
