@import "../../assets/styles/colors.scss";

.cta-secondary {
  background: linear-gradient(
    to bottom,
    var(--white) 0%,
    var(--white) 50%,
    $darkGrayishBlue200 50%,
    $darkGrayishBlue200 100%
  );
  padding: 93px 0 2px 0;

  .card {
    display: flex;
    background-color: var(--white);
    border: 0.063rem solid var(--blue100);
    box-shadow: 0px 0px 4px rgba(7, 7, 7, 0.16),
      0px 12px 12px -8px rgba(7, 7, 7, 0.16);
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-wrap: wrap;
    max-width: 74rem;
    margin: 0 auto;
    margin-bottom: 3.5rem;

    img {
      padding-left: 6.5rem;
      padding-right: 8.313rem;
    }

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: 1.5rem;
        text-align: center;
        font-family: preloslab-semibold, Helvetica, Arial, Sans, Sans-serif;
        margin-bottom: 1.5rem;
      }

      .buttons {
        display: flex;

        .tertiary {
          margin-right: 1rem;
        }

        @media (max-width: 991px) {
          .tertiary {
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
      }

      @media (max-width: 991px) {
        .buttons {
          flex-direction: column;
          padding: 0 2rem;
        }
      }
    }
  }

  .recaptcha-disclaimer {
    margin: 68px 16px 12px;
  }

  @media (max-width: 991px) {
    .card {
      padding: 2rem 1rem;
      justify-content: center;

      img {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

@media (max-width: 991px) {
  .cta-secondary {
    margin-top: 1.25rem;
  }
}
