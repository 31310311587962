@import "../assets/styles/colors.scss";

.backModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 700;
  background: rgba(74, 74, 80, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .position {
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;

    .box-modal {
      background: #ffffff;

      box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(7, 7, 7, 0.16);
      border-radius: 0.5rem;
      width: 39.5rem;
      height: auto;

      .modal-header {
        background: #ffffff;
        box-shadow: 0 0.25rem 0.25rem rgba(15, 38, 92, 0.1);
        border-radius: 0.5rem 0.5rem 0 0;
        height: 3.5rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 2rem;
        .icon-close {
          cursor: pointer;
        }
      }

      .content-modal::-webkit-scrollbar {
        width: 0.75rem;
        height: 30rem;
        -webkit-appearance: none;
      }

      .content-modal::-webkit-scrollbar-thumb {
        background: $darkGray200;
        border-radius: 0.313rem;
        background-clip: padding-box;
        border: 0.25rem solid rgba(0, 0, 0, 0);
      }

      .content-modal {
        margin-top: 1rem;
        padding-right: 2rem;
        margin-bottom: 0;
        margin-left: 2rem;
        height: auto;
        overflow-y: scroll;
        max-height: calc(100vh - 348px);

        .title-product {
          font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
          font-size: 1rem;
          line-height: 1.625rem;
          color: $blue500;
          text-align: center;
        }
        li {
          color: var(--blue500);
          font-size: 0.875rem;
          margin: 0;
          font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
          line-height: 1.438rem;
        }
        ul {
          list-style: none;
          margin: 0;
          padding-left: 1.313rem;
        }
        li::before {
          content: "\2022";
          display: inline-block;
          width: 0.75em;
          margin-left: -0.7em;
        }
        .last-line {
          color: var(--blue500);
          font-size: 0.75rem;
          margin: 0;
          font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
          line-height: 1.188rem;
          margin-bottom: 1.5rem;
        }
      }
      .divider {
        background: $darkGray100;
        width: 100%;
        height: 0.063rem;
      }
      .footer-modal {
        height: 5rem;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        .buttons-container {
          max-width: 6.125rem;
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 61.938rem) {
  .backModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 700;
    background-color: rgba(74, 74, 80, 0.8);
    width: 100%;
    height: 100%;
    .position {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      .box-modal {
        background: #ffffff;

        box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(7, 7, 7, 0.16);
        border-radius: 0.5rem;
        width: 20rem;
        margin-top: 5.313rem;
        margin-bottom: 5.313rem;
        height: auto;

        .modal-header {
          background: #ffffff;
          box-shadow: 0 0.25rem 0.25rem rgba(15, 38, 92, 0.1);
          border-radius: 0.5rem 0.5rem 0 0;
          height: 3.5rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 1rem;
        }
        .content-modal {
          margin-top: 1rem;
          padding-right: 1rem;
          margin-bottom: 0;
          margin-left: 1rem;
          height: auto;
          overflow-y: scroll;
          max-height: calc(100vh - 348px);

          .title-product {
            font-family: prelo-bold, Helvetica, Arial, Sans, Sans-serif;
            font-size: 0.875rem;
            line-height: 1.438rem;
            color: $blue500;
            text-align: center;
          }
          li {
            color: var(--blue500);
            font-size: 0.75rem;
            margin: 0;
            font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
            line-height: 1.188rem;
          }
          ul {
            list-style: none;
            margin: 0;
            padding-left: 0.9rem;
          }
          li::before {
            content: "\2022";
            display: inline-block;
            width: 0.75em;
            margin-left: -0.5em;
          }
          .last-line {
            color: var(--blue500);
            font-size: 0.75rem;
            margin: 0;
            font-family: prelo-medium, Helvetica, Arial, Sans, Sans-serif;
            line-height: 1.188rem;
            margin-bottom: 1.5rem;
          }
        }
        .divider {
          background: $darkGray100;
          width: 100%;
          height: 0.063rem;
        }
        .footer-modal {
          height: 5rem;
          padding: 0 2rem;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: center;
        }
      }
    }
  }
}
